import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromApp from 'src/app/_store/app.reducers';
import * as fromProfile from '../../../../pages/profile/store/profile.reducer';

@Component({
  selector: 'app-newsfeed-ctrl',
  templateUrl: './newsfeed-ctrl.component.html',
  styleUrls: ['./newsfeed-ctrl.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsfeedCtrlComponent implements OnInit {
  form: UntypedFormGroup;
  
  userInfoState: Observable<fromProfile.State>;

  @Output('openLogActivityModal') openLogActivityModal: EventEmitter<boolean> = new EventEmitter();;

  constructor(
    private store: Store<fromApp.AppState>,
    private fb: UntypedFormBuilder
  ) {
    this.userInfoState = this.store.select('userInfo');
  }

  ngOnInit() {
    this.form = this.fb.group({
      editor: [null, [Validators.required]]
    });
  }

  focusIn() {
    this.openLogActivityModal.emit(true);
  }

}
