import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value): {key: string, value: any}[] {
    const keys = [];
    if (value) {
      Object.keys(value).forEach((key) => {
        keys.push({key: key, value: value[key]});
      });
    }
    return keys;
  }
}
