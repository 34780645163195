import {AfterContentInit, Directive, ElementRef, NgZone, Renderer2} from '@angular/core';

@Directive({
  selector: '[appInputFocusTrigger]'
})
export class InputFocusTriggerDirective implements AfterContentInit {

  constructor(private element: ElementRef,
              private zone: NgZone,
              private renderer: Renderer2) {
  }

  ngAfterContentInit() {
    this.zone.runOutsideAngular(() => setTimeout(() => {
      this.renderer.selectRootElement(this.element.nativeElement).focus();
    }, 0));
  }

}
