import { LocalStorageService } from './../../../../_services/localstorage.service';
import * as UserFavoritesActions from './user-favorites.actions';

export interface State {
  id: number;
  userId: number;
  favorite: string;
  $$hashKey: string;
  dateCreated: number;
  dateModified: number;
}

const initialState: State[] = [];

const localStorageService = new LocalStorageService();
const LOCAL_STATE_KEY = 'stateUserFavorites';

export function UserFavoritesReducer(
  state = localStorageService.getInitialState(LOCAL_STATE_KEY, initialState),
  action: UserFavoritesActions.UserFavoritesActions
) {
  switch (action.type) {
    case (UserFavoritesActions.GET_USER_FAVORITES):
      return localStorageService.set(LOCAL_STATE_KEY, [
        ...action.payload
      ]);
    case (UserFavoritesActions.CLEAR_USER_FAVORITES):
      localStorageService.clear(LOCAL_STATE_KEY);
      return [
        ...initialState
      ];
    default:
      return state;
  }
}
