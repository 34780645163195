import { Action } from '@ngrx/store';
import { Category } from './explore.reducer';

export const SET_SEARCH_KEY = 'SET_SEARCH_KEY';
export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY';
export const SELECT_CATEGORY = 'SET_SELECTED_CATEGORIES';
export const DISABLE_SELECTED_CATEGORY = 'DISABLE_SELECTED_CATEGORY';
export const RAISE_PAGINATION_STEP = 'RAISE_PAGINATION_STEP';
export const LOWER_PAGINATION_STEP = 'LOWER_PAGINATION_STEP';

export interface ToggleCategoryPayload {
  index: number;
  value: boolean;
}

export class SetSearchKey implements Action {
  readonly type = SET_SEARCH_KEY;
  constructor(public payload: string | null) {}
}
export class ToggleCategory implements Action {
  readonly type = TOGGLE_CATEGORY;
  // constructor(public payload: ToggleCategoryPayload) {}
  constructor(public payload: Category[]) {}
}
export class RaisePaginationStep implements Action {
  readonly type = RAISE_PAGINATION_STEP;
  constructor(public payload: number) {}
}
export class LowerPaginationStep implements Action {
  readonly type = LOWER_PAGINATION_STEP;
  constructor(public payload: number) {}
}

export type ExploreActions =  SetSearchKey | ToggleCategory | RaisePaginationStep | LowerPaginationStep;
