import {
  MyQuestsActions, MyQuestActionTypes
} from './my-quests.actions';
import {
  MyQuestsFilterStats,
  MyQuestsFilterControls
} from '../../my-quests.types';

export interface MyQuestsState {
  isLoading: boolean;
  stats: MyQuestsFilterStats;
  filters: MyQuestsFilterControls;
}

export const initialState: MyQuestsState = {
  isLoading: false,
  stats: {
    active: 0,
    saved: 0,
    completed: 0
  },
  filters: {
    active: true,
    saved: true,
    completed: true,
    search: ''
  }
};

export function myQuestsReducer(state = initialState, action: MyQuestsActions): MyQuestsState {
  switch (action.type) {

    case MyQuestActionTypes.TryLoad:
      return {
        ...state,
        isLoading: action.payload
      };
    case MyQuestActionTypes.TryLoadSuccess:
      return {
        ...state,
        isLoading: false,
        stats: action.payload
      };
    case MyQuestActionTypes.UpdateStats:
      return {
        ...state,
        stats: action.payload
      };
    case MyQuestActionTypes.UpdateFilters:
      return {
        ...state,
        filters: action.payload
      };

    case MyQuestActionTypes.UpdateLoadStatus:
      return {
        ...state,
        isLoading: action.payload
      };

    default:
      return state;
  }
}

export const getMyQuestsStats = (state: MyQuestsState) => state.stats;
export const getIsLoadingStatus = (state: MyQuestsState) => state.isLoading;