import { NotificationType, ReadNotificationPayloadType } from './notification.types';

export class NotificationLogic {

  public static buildPayloadForReadNotification(notification: NotificationType): ReadNotificationPayloadType {
    switch (notification.type) {
      case 'QUEST_ACHIEVED':
      case 'QUEST_SAVED':
      case 'COMMENT':
      case 'QUEST_STARTED':
      case 'COMMENT_LIKED':
      case 'MILESTONE_ACHIEVED':
      case 'PHOTO_VIDEO_ADDED':
      case 'QUEST_BACKED':
      case 'EVENT_STARTED':
      case 'FUNDRAISER_STARTED':
      case 'FOLLOWED':
        return {
          notificationType: notification.type,
          questId: notification.quest.id
        };
      case 'FRIEND_REQUEST':
      case 'PROFILE_BACKED':
      default:
        return {
          notificationType: notification.type,
          questId: null
        };
    }
  }
}
