import * as ExploreActions from './explore.actions';

export interface Category {
  title: string;
  isActive: boolean;
}

export interface State {
  categories: Category[];
  keyword: string | null;
  pagination: { from: number, to: number };
}

export const loadStep = 15;
export const initialState: State = {
  categories: [
    { title: 'PHYSICAL',      isActive: false },
    { title: 'MENTAL',        isActive: false },
    { title: 'SOCIAL',        isActive: false },
    { title: 'OCCUPATIONAL',  isActive: false },
    { title: 'FINANCIAL',     isActive: false },
    { title: 'ENVIRONMENTAL', isActive: false }
  ],
  keyword: '',
  pagination: {
    from: 0,
    to: loadStep
  }
};

export function ExploreReducer (state = initialState, action: ExploreActions.ExploreActions) {
  switch (action.type) {
    case (ExploreActions.SET_SEARCH_KEY):
      const ssk_pagination = {...state.pagination};
      ssk_pagination.from = 0;
      ssk_pagination.to = loadStep;
      return {
        ...state,
        keyword: action.payload,
        pagination: ssk_pagination
      };
    case (ExploreActions.TOGGLE_CATEGORY):
      // const categories = [...state.categories];
      // categories[action.payload.index].isActive = action.payload.value;
      const tc_pagination = {...state.pagination};
      tc_pagination.from = 0;
      tc_pagination.to = loadStep;
      return {
        ...state,
        // categories: categories,
        categories: action.payload,
        pagination: tc_pagination
      };
    case (ExploreActions.RAISE_PAGINATION_STEP):
      const rps_pagination = {...state.pagination};
      rps_pagination.from += action.payload;
      rps_pagination.to += action.payload;
      return {
        ...state,
        pagination: rps_pagination
      };
    case (ExploreActions.LOWER_PAGINATION_STEP):
      const lps_Pagination = {...state.pagination};
      lps_Pagination.from -= action.payload;
      lps_Pagination.to -= action.payload;
      return {
        ...state,
        pagination: lps_Pagination
      };
    default:
      return state;
  }
}
