import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'digitk'})
export class DigitkPipe implements PipeTransform {
  lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];

  transform(value: string | number): string {
    let _val = parseFloat(value + '');
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = this.lookup.slice().reverse().find(function(item) {
      return _val >= item.value;
    });
    return item ? (_val / item.value).toFixed(1).replace(rx, "$1") + item.symbol : "0";
  }
}
