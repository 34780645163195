import {
  CLOSE_TEAM_MODAL,
  CREATE_TEAM,
  FUNDRAISER_STARTED,
  JOIN_TEAM,
  OPEN_TEAM_MODAL,
  OpenTeamModal,
  QUEST_STARTED,
  QuestActions,
  START_FUNDRAISER,
  START_QUEST,
  TEAM_CREATED,
  TEAM_JOINED
} from './quest-detail.actions';

export interface TeamModalOpenState {
  open: boolean;
  selectedId: number;
}

export interface TeamModalProgressState {
  questProgress: boolean;
  fundProgress: boolean;
}

export interface TeamModalState {
  openState: TeamModalOpenState;
  progressState: TeamModalProgressState;
}

const initialState: TeamModalState = {
  openState: {
    open: false,
    selectedId: null
  },
  progressState: {
    questProgress: false,
    fundProgress: false
  }
};

export function QuestTeamModalReducer(state = initialState, action: QuestActions): TeamModalState {
  switch (action.type) {
    case OPEN_TEAM_MODAL:
      return {...state, openState: {...state.openState, open: true, selectedId: (action as OpenTeamModal).teamId}};
    case CLOSE_TEAM_MODAL:
      return {...state, openState: {...state.openState, open: false, selectedId: null}};
    case CREATE_TEAM:
    case JOIN_TEAM:
    case START_QUEST:
      return {...state, progressState: {...state.progressState, questProgress: true}};
    case TEAM_CREATED:
    case TEAM_JOINED:
    case QUEST_STARTED:
      return {...state, progressState: {...state.progressState, questProgress: false}};
    case START_FUNDRAISER:
      return {...state, progressState: {...state.progressState, fundProgress: true}};
    case FUNDRAISER_STARTED:
      return {...state, progressState: {...state.progressState, fundProgress: false}};
    default:
      return state;
  }
}
