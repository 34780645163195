import {Action, ActionReducer, ActionReducerMap, createSelector, MetaReducer} from '@ngrx/store';
import * as fromAuth from '../components/main/auth/store/auth.reducer';
import * as fromProfile from '../components/pages/profile/store/profile.reducer';
import * as fromUserFavorites from '../components/pages/profile/store/user-favorites.reducer';
import * as fromRecentTransactions from '../components/pages/profile/store/recent-transactions.reducer';
import * as fromPaymentsReducer from '../components/pages/account/payments/store/payments.reducer';
import * as fromExploreReducer from '../components/pages/explore/store/explore.reducer';
import * as fromDlExploreReducer from '../modules/explore/store/explore.reducer';
import * as fromDonateReducer from '../components/pages/donate/store/donate.reducer';
import * as fromQuestComponentsReducer from '../components/pages/quest-detail/quest-comments/store/quest-components.reducer';
import * as fromMyQuestsReducer from '../components/pages/my-quests/store/my-quests.reducer';
import * as fromNotifications from '../components/main/notifications/store/notifications.reducer';
import * as fromTicketFlow from '../modules/ticket-flow/pages/ticket-flow/store/ticket-flow.reducer';
import * as AuthActions from '../components/main/auth/store/auth.actions';
import * as fromTierReducer from '../components/pages/diemlife-upgrade/tier-upgrade/store/tier-upgrade.reducer';
import { Transaction } from '../_interface/user-profile.types';
import { QuestDetailState, QuestReducer, questTeamsChanged } from '../_interface/store/quest-detail.reducers';
import { QuestTeamModalReducer, TeamModalState } from '../_interface/store/quest-team-modal.reducer';
import { getIsLoadingStatus, getMyQuestsStats, myQuestsReducer, MyQuestsState } from '../_interface/store/my-quests/my-quests.reducer';
import { dlMilestonesReducer, TaskGroupsState } from '../_interface/dl-milestones.reducer';
import * as fromAccount from '../components/pages/account/store/account.reducer';

export type anyState = fromAuth.State |
  fromProfile.State |
  fromUserFavorites.State[] |
  Transaction[] |
  fromPaymentsReducer.State |
  fromExploreReducer.State |
  fromDlExploreReducer.State |
  fromDonateReducer.State |
  QuestDetailState |
  TeamModalState |
  fromQuestComponentsReducer.State |
  fromMyQuestsReducer.State |
  fromNotifications.State |
  fromTicketFlow.State |
  TaskGroupsState |
  MyQuestsState |
  fromAccount.State |
  fromTierReducer.State;

export interface AppState {
  auth: fromAuth.State;
  userInfo: fromProfile.State;
  userFavorites: fromUserFavorites.State[];
  recentTransactions: Transaction[];
  paymentsInfo: fromPaymentsReducer.State;
  explore: fromExploreReducer.State;
  dlExplore: fromDlExploreReducer.State;
  donate: fromDonateReducer.State;
  quest: QuestDetailState;
  team: TeamModalState;
  questComponents: fromQuestComponentsReducer.State;
  myQuests: fromMyQuestsReducer.State;
  notifications: fromNotifications.State;
  ticketFlow: fromTicketFlow.State;
  dlMilestones: TaskGroupsState;
  dlMyQuests: MyQuestsState;
  account: fromAccount.State;
  tierUpgrade: fromTierReducer.State;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  userInfo: fromProfile.ProfileReducer,
  userFavorites: fromUserFavorites.UserFavoritesReducer,
  recentTransactions: fromRecentTransactions.RecentTransactionsReducer,
  paymentsInfo: fromPaymentsReducer.paymentsReducer,
  explore: fromExploreReducer.ExploreReducer,
  dlExplore: fromDlExploreReducer.DlExploreReducer,
  donate: fromDonateReducer.DonateReducer,
  team: QuestTeamModalReducer,
  quest: QuestReducer,
  questComponents: fromQuestComponentsReducer.commentsReducer,
  myQuests: fromMyQuestsReducer.MyQuestsReducer,
  notifications: fromNotifications.NotificationsReducer,
  ticketFlow: fromTicketFlow.TicketFlowReducer,
  dlMilestones: dlMilestonesReducer,
  dlMyQuests: myQuestsReducer,
  account: fromAccount.AccountReducer,
  tierUpgrade: fromTierReducer.TierUpgradeReducer
};

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state: AppState, action: Action): AppState {
    if (action.type === AuthActions.LOGOUT) {
      console.info('Clearing global application state on logout');
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [clearState];

export const teamStateSelect = (state: AppState) => state.team;
export const questStateSelect = (state: AppState) => state.quest;
export const dlMyQuestsSelect = (state: AppState) => state.dlMyQuests;
export const ticketFlowSelect = (state: AppState) => state.ticketFlow;
export const authSelect = (state: AppState) => state.auth;

export const teamModalOpenState = createSelector(teamStateSelect, (state: TeamModalState) => state.openState);
export const teamModalProgressState = createSelector(teamStateSelect, (state: TeamModalState) => state.progressState);
export const questDetailState = createSelector(questStateSelect, (state) => state);

export const myQuestsListGetStats = createSelector(dlMyQuestsSelect, getMyQuestsStats);
export const myQuestsIsLoadingStatus = createSelector(dlMyQuestsSelect, getIsLoadingStatus);

export const ticketFlowPaymentData = createSelector(ticketFlowSelect, (state) => {
  return state && state.selectedPaymentDetail;
});

export const ticketFlowPaymentStatus = createSelector(ticketFlowSelect, (state) => {
  return state && state.paymentTypeConfirm;
});

export const authFormMode = createSelector(authSelect, fromAuth.getFormMode);

export const ticketBuyerChange = createSelector(ticketFlowSelect, fromTicketFlow.buyerChanged);
export const signUpTicketChange = createSelector(ticketFlowSelect, fromTicketFlow.signUpTicketChange);
export const eventDetailsChangeInTicket = createSelector(ticketFlowSelect, fromTicketFlow.eventDetailsChange);
export const teamListChangeInTicket = createSelector(ticketFlowSelect, fromTicketFlow.teamListChange);
export const ticketVariantsChangeInTicket = createSelector(ticketFlowSelect, fromTicketFlow.ticketVariantsChange);
export const participantInfoChangeInTicket = createSelector(ticketFlowSelect, fromTicketFlow.participantInfoChange);
export const participantValidArrChangeInTicket = createSelector(ticketFlowSelect, fromTicketFlow.participantValidArrChange);
export const selectedTeamChangeInTicket = createSelector(ticketFlowSelect, fromTicketFlow.selectedTeamChange);


export const selectedQuestTeamsChanged = createSelector(questStateSelect, questTeamsChanged);
