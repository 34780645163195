import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../../main/contact-us/contact-us.component';

@Component({
  selector: 'app-common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonFooterComponent implements OnInit {
  modalReference: any;
  
  year: number;

  constructor(private modalService: NgbModal,
    @Inject ('isServer') private isServer: boolean,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.year = new Date().getUTCFullYear()

    this.cdr.detectChanges();
  }

  openContactForm(): void {
    if (this.isServer) return;
    
    this.modalReference = this.modalService.open(ContactUsComponent, {
      windowClass: 'no-overflow'
    });
  }
}
