import { Directive } from '@angular/core';

@Directive({
  selector: '[appGetInView]'
})
export class GetInViewDirective {

  constructor() { }

}
