import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {DirectionsType, MapLocation, MapRoute} from '../map.types';

@Injectable()
export class MapboxService {

  private static readonly API_BASE: string = 'https://api.mapbox.com/directions/v5';

  constructor(private http: HttpClient) {
  }

  public getDirections(from: MapLocation, to: MapLocation, type: DirectionsType, apiKey: string): Observable<MapRoute> {
    const url = `${MapboxService.API_BASE}/${type}/${from.longitude},${from.latitude};${to.longitude},${to.latitude}?steps=true&geometries=geojson&access_token=${apiKey}`;
    return this.http.get<MapboxDirections>(url).pipe(
      take(1),
      map(directions => {
        if (directions && directions.routes && directions.routes.length) {
          const route = directions.routes[0];
          const result: MapRoute = {
            distance: route.distance,
            coordinates: []
          };
          const geometry = route.geometry;
          if (geometry && geometry.coordinates.length) {
            result.coordinates.push(...(geometry.coordinates.filter(pair => pair && pair.length === 2).map(pair => {
              return {
                longitude: pair[0],
                latitude: pair[1]
              } as MapLocation;
            })));
          }
          return result;
        } else {
          return null;
        }
      }),
      catchError(error => {
        console.log('Unable to load directions from Mapbox', error)
        return EMPTY;
      })
    );
  }

}

interface MapboxDirections {
  routes: MapboxRoute[];
}

interface MapboxRoute {
  geometry: MapboxGeometry;
  distance: number;
}

interface MapboxGeometry {
  coordinates: number[][];
  type: string;
}
