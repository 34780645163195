export enum StripeErrorCodesEnum {
  card_declined = "Your card was declined. Please check with your bank or try another card.",
  balance_insufficient = "Your card was declined. Please check with your bank or try another card.",
  incorrect_cvc = "The CVC you entered is incorrect. Please verify and try again.",
  invalid_cvc = "The CVC you entered is incorrect. Please verify and try again.",
  bank_account_unverified = "Please verify your bank account details.",
  bank_account_exists = "This account already exists. Please use a different one.",
  country_unsupported = "We do not support this country at the moment.",
  coupon_expired = "This coupon has expired. Please try another.",
  expired_card = "Your card has expired. Please use a different one.",
  incorrect_zip = "Please verify your details.",
  invalid_expiry_month = "Please verify your details.",
  invalid_expiry_year = "Please verify your details.",
  invalid_number = "Please verify your details.",
  order_creation_failed = "Your order failed. Please check your details and try again.",
  postal_code_invalid = "Please verify your details.",
  sku_inactive = "This product is inactive. Please check back later."
}
