import { Action } from '@ngrx/store';
import {
  MyQuestsFilterStats,
  MyQuestsFilterControls
} from '../../my-quests.types';

export enum MyQuestActionTypes {
  UpdateStats = '[MyQuests] UpdateStats',
  UpdateFilters = '[MyQuests] UpdateFilters',
  UpdateLoadStatus = '[MyQuests] UpdateLoadStatus',
  TryLoad = '[MyQuests] TryLoad',
  TryLoadSuccess = '[MyQuests] TryLoadSuccess'
}

export class UpdateStats implements Action {
  readonly type = MyQuestActionTypes.UpdateStats;

  constructor(public payload: MyQuestsFilterStats) {}
}
export class UpdateFilters implements Action {
  readonly type = MyQuestActionTypes.UpdateFilters;

  constructor(public payload: MyQuestsFilterControls) {}
}
export class UpdateLoadStatus implements Action {
  readonly type = MyQuestActionTypes.UpdateLoadStatus;

  constructor(public payload: boolean) {}
}
export class TryLoad implements Action {
  readonly type = MyQuestActionTypes.TryLoad;

  constructor(public payload: boolean) {}
}
export class TryLoadSuccess implements Action {
  readonly type = MyQuestActionTypes.TryLoadSuccess;

  constructor(public payload: MyQuestsFilterStats) {}
}

export type MyQuestsActions = UpdateFilters |
  UpdateLoadStatus |
  UpdateStats |
  TryLoad |
  TryLoadSuccess;
