import { Directive, ElementRef, OnInit, OnDestroy, HostListener, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { QuestDoer } from 'src/app/_interface/quest.types';
import { WindowRef } from '../../../_global/window-ref.module';

declare var $: any;

@Directive({
  selector: '[appMembers]'
})
export class MembersDirective implements OnInit, OnDestroy, OnChanges {
  // tslint:disable-next-line:max-line-length
  @Output() viewLimit: EventEmitter<{viewLimit: number, moreBtnIsVisible: boolean}> = new EventEmitter<{viewLimit: number, moreBtnIsVisible: boolean}>();
  @Input() doersLength: number;
  moreBtn: any;
  doers: QuestDoer[];
  jqueryMoreBtn: any;
  constructor(
    private winRef: WindowRef,
    private elementRef: ElementRef
  ) {}

  ngOnInit () {
    setTimeout(() => {
      this.moreBtn = this.elementRef.nativeElement.getElementsByClassName('c-quest__doers__more')[0];
      this.jqueryMoreBtn = $(this.moreBtn);
      this.doers = this.elementRef.nativeElement.getElementsByClassName('c-quest__doer');
    });
    this.setupCount();
  }
  ngOnChanges () {
    this.setupCount();
  }
  setupCount() {
    setTimeout(() => {
      this.updateMoreBtnText(this.winRef.nativeWindow.innerWidth);
    });
  }
  updateMoreBtnText(innerWidth) {
    if (this.doers) {
      let viewLimit = this.doers.length - 1;
      for (let i = 0; i < this.doers.length; i++) {
        if (this.winRef.nativeWindow.getComputedStyle(this.doers[i]).display === 'none') {
          viewLimit--;
        }
      }
      this.viewLimit.emit({ viewLimit: viewLimit, moreBtnIsVisible: this.jqueryMoreBtn.is(':visible') });
      if (this.moreBtn && this.doersLength > viewLimit) {
        this.moreBtn.innerText = '+' + (this.doersLength - viewLimit);
      } else {
        this.moreBtn.innerHTML = 'View<br>All';
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateMoreBtnText(event.target.innerWidth);
  }

  ngOnDestroy () {
    // console.log('ngOnDestroy');
  }

}
