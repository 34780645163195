import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, switchMap } from "rxjs/operators";
import { ReaquestHeadersService } from "src/app/_services/requestHeaders.service";
import { environment } from "src/environments/environment";
import * as fromApp from '../../_store/app.reducers';
import { QuestGalleryImage } from "../quest.types";
import { GET_QUEST_GALLERY_ASSETS, SetQuestGalleryAssets } from "./quest-detail.actions";

@Injectable()
export class QuestDetailEffects {

  constructor(
    private actions$: Actions,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) {}

  getGalleryAssets = createEffect(() => this.actions$
    .pipe(
      ofType(GET_QUEST_GALLERY_ASSETS),
      switchMap((questData: {questId: number, userId: number}) => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
            environment.target + environment.context + `/quest-gallery/${questData.questId}/${questData.userId}`,
            {headers: headers}
        ).pipe(
            map((res: QuestGalleryImage[]) => {
                this.store.dispatch(new SetQuestGalleryAssets(res));
            }) 
        );
      })
    ), {dispatch: false});

}