import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ReaquestHeadersService } from './requestHeaders.service';
import { LocalStorageService } from './localstorage.service';
import * as slugify from 'slugify';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataService {

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private localStorageService: LocalStorageService
  ) { }

  getSupportedCountries() {
    return this.http.get(`/assets/data/countries.en.min.json`);
  }

  getRegions(countryCode: string) {
    return this.http.get(`/assets/data/regions-${countryCode}.json`);
  }

  verifyCapcha (key: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/recaptcha/verify/${key}`,
      { headers: headers }
    );
  }
  slugify(paragraph: string): string {
    const slugifyValid: any = slugify;
    return slugifyValid(paragraph);
  }
}
