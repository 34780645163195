import { Directive, ElementRef, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
declare var $: any;

@Directive({
  selector: '[bs-select-directive]'
})
export class BsSelectDirective implements OnInit, OnDestroy {
  @Input() events: Observable<void | string>;
  @Input() options?: any[];
  @Input() selectedValue?: any;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Input() size?: number;
  eventsSubscription: any;
  // jqueryElement: JQuery;
  jqueryElement: any;
  selectOptions: Selectpicker = {
    width: '100%',
    windowPadding: [10, 0, 10, 0]
  };
  constructor(private elementRef: ElementRef) {
    this.jqueryElement = $(this.elementRef.nativeElement);
  }

  ngOnInit() {
    setTimeout(() => {
      const selectOptions: any = {...this.selectOptions};
      if (this.size) {
        selectOptions.size = this.size;
      }
      this.jqueryElement.selectpicker(this.selectOptions);
      this.refresh();
      this.jqueryElement.on('hidden.bs.select', (e, clickedIndex, isSelected, previousValue) => {
        this.close.emit();
        this.refresh();
      });

      this.jqueryElement.on('show.bs.select', (e, clickedIndex, isSelected, previousValue) => {
        setTimeout(() => {
          this.close.emit();          
        }, 100);
      });

      if (this.events) {
        this.eventsSubscription = this.events.subscribe(() => {
          this.refresh();
        });
      } else {
        this.refresh();
      }
    });
  }

  refresh() {
    setTimeout(() => {
      if (this.selectedValue && this.selectedValue !== '') {
        this.jqueryElement.val(this.selectedValue);
      }
      this.jqueryElement.selectpicker('refresh');      
      if (this.options) {
        const options = this.options;
        const btn = this.jqueryElement.closest('.bootstrap-select').find('.filter-option-inner-inner');
        const selectedIndex = options.map(option => option.label).indexOf(btn.text());
        
        if (selectedIndex !== -1) {
          btn.empty().append(options[selectedIndex].content);
        }
        this.jqueryElement.closest('.bootstrap-select').find('.dropdown-menu.inner li a').each(function(index, item) {
          if (options[index].content) {
            $(item).empty().append(options[index].content);
          }
        });
      }
    });
  }

  ngOnDestroy () {
    this.jqueryElement.selectpicker('destroy');
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }
}
