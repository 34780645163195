import { AccountPlan } from 'src/app/_interface/account-tier.types';
import * as TierUpgradeActions from './tier-upgrade.actions';

export interface SelectedPlanDetail {
    planType: string;
    planPrice: number;
}

export interface TierInformation {
    id: number;
    level: string;
    monthlyPricing: number;
    annualPricing: number;
    allowedMembers: number;
    companyId: number;
    allowedQuests: number;
    planGroupId: number;
    isPrivate: boolean;
}

export interface PaymentInfo {
    lastFour: string;
    paymentMode: string;
    save: boolean;
    token: string;
}

export interface PaymentBillingInfo {
    formValid: boolean;
    formValues: any;
}

export interface CouponDetails {
    type: string;
    value: string;
    freeTrialDays: number;
}

export interface State {
    selectedTier: AccountPlan;
    stepsCompleted: number;
    currentStep: number;
    tierVariantsOf: string;
    tierVariants: AccountPlan[];
    submitPayment: boolean;
    paymentInfo: PaymentInfo;
    paymentBilling: PaymentBillingInfo;
    selectedPlanType: SelectedPlanDetail;
    couponDetails: CouponDetails;
    loading: boolean;
    donateData: {questId: number; user: number; amount: number; tipAmount: number;};
}

export const initialState: State = {
    selectedTier: null,
    stepsCompleted: 0,
    currentStep: 1,
    tierVariantsOf: null,
    tierVariants: [],
    submitPayment: false,
    paymentInfo: {
        lastFour: '',
        paymentMode: '',
        save: false,
        token: ''
    },
    paymentBilling: {
        formValid: false,
        formValues: null
    },
    selectedPlanType: null,
    couponDetails: {
        freeTrialDays: 0,
        value: '',
        type: ''
    },
    loading: false,
    donateData: null
};

export function TierUpgradeReducer(state = initialState, action: TierUpgradeActions.TierUpgradeActions) {
    switch (action.type) {
        case (TierUpgradeActions.SET_SELECTED_TIER):
            return {
                ...state,
                selectedTier: action.payload
            };
        case (TierUpgradeActions.SET_TIER_VARIANTS):
            return {
                ...state,
                tierVariantsOf: action.tierDetailsOf,
                tierVariants: action.payload
            };
        case (TierUpgradeActions.UPDATE_STEPS_COMPLETED):
            return {
                ...state,
                stepsCompleted: action.payload
            };
        case (TierUpgradeActions.SET_CURRENT_STEP):
            return {
                ...state,
                currentStep: action.payload
            };
        case (TierUpgradeActions.SET_PROCEED_FOR_PAYMENT):
            return {
                ...state,
                submitPayment: action.payload
            };
        case (TierUpgradeActions.SET_PAYMENT_INFO):
            return {
                ...state,
                paymentInfo: action.payload
            };
        case (TierUpgradeActions.SET_PAYMENT_BILLING_INFO):
            return {
                ...state,
                paymentBilling: action.payload
            }
        case (TierUpgradeActions.SET_SELECTED_PLAN_TYPE):
            return {
                ...state,
                selectedPlanType: action.payload
            }
        case (TierUpgradeActions.SET_COUPON_DETAILS):
            return {
                ...state,
                couponDetails: action.payload
            }
        case TierUpgradeActions.IS_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case TierUpgradeActions.DONATE_DATA_MEMBERSHIP:
            return {
                ...state,
                donateData: action.payload
            }
        default:
            return state;
    }
}
