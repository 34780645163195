import * as DlExploreActions from './explore.actions';

export interface Pillar {
  value: string;
  isActive: boolean;
}
export interface SortFilterItem {
  title: string;
  value: string;
}

export interface State {
  pillars: Pillar[];
  places: {
    selected: number;
    value: { value: string; name: string; }[];
  };
  categories: {
    selected: number;
    value: string[];
  };
  pagination: { from: number, to: number };
}

export const loadStep = 15;
export const dlInitialState: State = {
  pillars: [],
  places: {
    selected: null,
    value: []
  },
  categories: {
    selected: null,
    value: []
  },
  pagination: {
    from: 0,
    to: loadStep
  }
};

export function DlExploreReducer(state = dlInitialState, action: DlExploreActions.DlExploreActions) {
  switch (action.type) {
    case (DlExploreActions.DL_TOGGLE_PILLAR):
      const tcPagCategory = {...state.pagination};
      tcPagCategory.from = 0;
      tcPagCategory.to = loadStep;
      const pillars = [...state.pillars];
      pillars[action.payload].isActive = !pillars[action.payload].isActive;
      return {
        ...state,
        pillars: pillars,
        pagination: tcPagCategory
      };
    case (DlExploreActions.DL_SWITCH_TO_ONLY_PILLAR):
      const tcPagOnlyCategory = {...state.pagination};
      tcPagOnlyCategory.from = 0;
      tcPagOnlyCategory.to = loadStep;
      const pillarsForOlnly = [...state.pillars].map(pillar => {
        return {
          value: pillar.value,
          isActive: false
        };
      });
      pillarsForOlnly[action.payload].isActive = true;
      return {
        ...state,
        pillars: pillarsForOlnly,
        pagination: tcPagOnlyCategory
      };
    case (DlExploreActions.DL_SWITCH_CATEGORY):
      const switchCategoryPag = {...state.pagination};
      switchCategoryPag.from = 0;
      switchCategoryPag.to = loadStep;
      const switchCategoryPillars = [...state.pillars].map(pillar => {
        return {...pillar, isActive: false};
      });
      return {
        ...state,
        pillars: switchCategoryPillars,
        categories: {
          ...state.categories,
          selected: action.payload
        },
        pagination: switchCategoryPag
      };
    case (DlExploreActions.DL_SWITCH_PLACE):
      const switchPlacePag = {...state.pagination};
      switchPlacePag.from = 0;
      switchPlacePag.to = loadStep;
      const switchPlacePillars = [...state.pillars].map(pillar => {
        return {...pillar, isActive: false};
      });
      return {
        ...state,
        pillars: switchPlacePillars,
        places: {
          ...state.places,
          selected: action.payload
        },
        pagination: switchPlacePag
      };
    case (DlExploreActions.DL_CLEAR_FILTERS):
      const clearFiltersPag = {...state.pagination};
      clearFiltersPag.from = 0;
      clearFiltersPag.to = loadStep;
      return {
        ...state,
        places: {
          selected: null,
          value: state.places.value
        },
        categories: {
          selected: null,
          value: state.categories.value
        },
        pagination: clearFiltersPag
      };
    case (DlExploreActions.DL_RESET_PAGINATION_STEP):
      const dlResetPagination = {...state.pagination};
      dlResetPagination.from = 0;
      dlResetPagination.to = loadStep;
      return {
        ...state,
        pagination: dlResetPagination
      };
    case (DlExploreActions.DL_RAISE_PAGINATION_STEP):
      const rpsPagination = {...state.pagination};
      rpsPagination.from += action.payload;
      rpsPagination.to += action.payload;
      return {
        ...state,
        pagination: rpsPagination
      };
    case (DlExploreActions.DL_LOWER_PAGINATION_STEP):
      const lpsPagination = {...state.pagination};
      lpsPagination.from -= action.payload;
      lpsPagination.to -= action.payload;
      return {
        ...state,
        pagination: lpsPagination
      };
    case (DlExploreActions.DL_SET_FILTERS):
      return {
        ...state,
        places: {
          selected: null,
          value: action.payload.places
        },
        categories: {
          selected: null,
          value: action.payload.categories
        },
        pillars: action.payload.pillars
      };
    default:
      return state;
  }
}
