import {Action} from '@ngrx/store';
import { Transaction } from 'src/app/_interface/user-profile.types';

export const TRY_GET_RECENT_TRANSACTIONS = 'TRY_GET_RECENT_TRANSACTIONS';
export const GET_RECENT_TRANSACTIONS = 'GET_RECENT_TRANSACTIONS';
export const CLEAR_RECENT_TRANSACTIONS = 'CLEAR_RECENT_TRANSACTIONS';

export class TryGetRecentTransactions implements Action {
  readonly type = TRY_GET_RECENT_TRANSACTIONS;
}

export class GetRecentTransactions implements Action {
  readonly type = GET_RECENT_TRANSACTIONS;

  constructor(public payload: Transaction[]) {}
}

export class ClearRecentTransactions implements Action {
  readonly type = CLEAR_RECENT_TRANSACTIONS;
}

export type RecentTransactionsActions = TryGetRecentTransactions |
                                        GetRecentTransactions |
                                        ClearRecentTransactions;
