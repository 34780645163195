import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'timeAgo'})
export class TimeAgoPipe implements PipeTransform {
  transform(time: number | string, isUtc?: boolean): string {
    if (isUtc) {
      const diff = moment.utc(time).local().diff(new Date());
      return moment.utc(time).local().subtract(diff > 0 ? diff : 0).fromNow();
    }

    const diff = moment(time).diff(new Date());
    return moment(time).subtract(diff > 0 ? diff : 0).fromNow();
  }
}
