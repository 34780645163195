import { NotificationType } from 'src/app/_interface/notification.types';
import * as NotificationsActions from './notifications.actions';
import { ExploreCardListType } from 'src/app/_interface/explore-page.types';

export interface State {
  active: boolean;
  notEmpty: boolean;
  count: number;
  notifications: NotificationType[];
  hasCalledNotificationApi: boolean;
  userQuests: { [key: number]: ExploreCardListType };
  loadingNotifications: boolean;
  allLoaded: boolean;
}

export const initialState: State = {
  active: null,
  notEmpty: false,
  count: 0,
  notifications: [],
  hasCalledNotificationApi: false,
  userQuests: {},
  loadingNotifications: false,
  allLoaded: false
};

export function NotificationsReducer (state = initialState, action: NotificationsActions.NotificationsActions) {
  switch (action.type) {
    case (NotificationsActions.SET_NOTIFICATIONS_INFO):
      return {
        ...state,
        notEmpty: !!action.payload,
        count: action.payload
      };
    case (NotificationsActions.SHOW_NOTIFICATIONS):
      return {
        ...state,
        active: true
      };
    case (NotificationsActions.HIDE_NOTIFICATIONS):
      return {
        ...state,
        active: false
      };
    case (NotificationsActions.RESET_NOTIFICATIONS):
      return {
        ...initialState
      };
    case (NotificationsActions.SET_NOTIFICATIONS):
      const newNotifs = [...state.notifications, ...action.payload];
      return {
        ...state,
        notifications: newNotifs
      };
    case (NotificationsActions.CALLED_NOTIFICATION_API):
      return {
        ...state,
        hasCalledNotificationApi: action.payload        
      }
    case NotificationsActions.SET_USER_QUESTS:
      return {
        ...state,
        userQuests: action.payload
      }
    case NotificationsActions.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        ...initialState
      };
    case NotificationsActions.MARK_ALL_NOTIFICATION_READ:
      let _notifications = state.notifications;
      _notifications.map(notif => notif.isRead = true);
      return {
        ...state,
        count: 0,
        notifications: _notifications
      };
    case NotificationsActions.IS_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loadingNotifications: action.payload
      };
    case NotificationsActions.ALL_NOTIFICATIONS_LOADED:
      return {
        ...state,
        allLoaded: action.payload        
      }
    default:
      return state;
  }
}
