import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {ReaquestHeadersService} from '../../../../_services/requestHeaders.service';
import {Observable, throwError} from 'rxjs';
// import { isNumber } from 'util';
import {catchError, map} from 'rxjs/operators';
import {BankAccount} from "./store/payments.reducer";

export interface VefifyBankAccount {
  firstDebit: string;
  secondDebit: string;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) {}

  verifyBankAccount(payload: VefifyBankAccount) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/accounts/bank-accounts/verify',
      payload,
      { headers: headers }
    );
  }
  getSupportedCountries() {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/ref/supported-countries',
      {
        headers: headers
      }
    );
  }

  checkAccountValidation() {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/accounts/check-info',
      {
        headers: headers
      }
    );
  }

  activatePayments() {
    const headers: HttpHeaders = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/account/activate-payments',
      {headers: headers}
    );
  }

  testStripeAccount(userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/accounts/test-stripe-account/${userId}`,
      {
        headers: headers
      }
    ).pipe(
      map(e => {
        return true;
      }),
      catchError(e => {
        return throwError(false);
      })
    );
  }

  saveCreditCard(token: string) {
    const headers: HttpHeaders = this.reaquestHeadersService.getHeaders();
    const payload = {token: token};
    return this.http.post(
      environment.target + environment.context + '/accounts/savecreditcard',
      payload,
      {headers: headers}
    );
  }

  removeCreditCard(lastFourDigits: string) {
    const headers: HttpHeaders = this.reaquestHeadersService.getHeaders();
    const params = {lastFour: lastFourDigits};
    return this.http.post(
      environment.target + environment.context + '/accounts/deletecreditcard',
      null,
      {headers: headers, params: params}
    );
  }

  saveBankAccount(purpose: string, token: string): Observable<BankAccount> {
    const headers: HttpHeaders = this.reaquestHeadersService.getHeaders();
    const payload = {token: token};
    return this.http.post<BankAccount>(
      environment.target + environment.context + `/accounts/bank-accounts/save/${purpose}`,
      payload,
      {headers: headers}
    );
  }

  removeBankAccount() {
    const headers: HttpHeaders = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/accounts/bank-accounts/delete',
      null,
      {headers: headers}
    );
  }

  breakdownQuest(questId: number,
                 doerId: number,
                 teamId: number,
                 brandUserId: number = null,
                 amount: number,
                 tip: number = null,
                 paymentMode: string,
                 backerAbsorbsFees: boolean) {
    const headers: HttpHeaders = this.reaquestHeadersService.getHeaders();
    const payload = {
      amount: amount,
      tip: tip,
      brandUserId: brandUserId,
      paymentMode: paymentMode,
      backerAbsorbsFees: backerAbsorbsFees
    };
    return this.http.post(
      environment.target + environment.context + `/quests/${questId}/breakdown-quest/${doerId}/${teamId || 0}`,
      payload,
      {headers: headers}
    );
  }

  isNumber(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(Number(n));
  }

  getAmountInCents(amount: number): number {
    if (!this.isNumber(amount)) {
      return 0;
    }
    const amountInCents = Math.round(amount * 100.0);
    return amountInCents < 0 ? 0 : amountInCents;
  }

  getWalletSecretKey(amount: number, currency: string, paymentMethod: string) {
    const headers: HttpHeaders = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quest-leaderboard/secret/${amount}/${currency}/${paymentMethod}`,
      {headers: headers}
    )
  }
  getWalletSecretKey2(amount: number, currency: string, paymentMethod: string) {
    const headers: HttpHeaders = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quest-leaderboard/secret-copy/${amount}/${currency}/${paymentMethod}`,
      {headers: headers}
    )
  }

  getPaymentIntent(amount: number, currency: string): Observable<string> {
    const headers: HttpHeaders = this.reaquestHeadersService.getHeaders();
    return this.http.get<string>(
      environment.target + environment.authContext + `/paymentIntent/${amount}/${currency}`,
      {headers: headers}
    )
  }
}
