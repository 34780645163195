import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TeamAvatarService {
  count = 0;
  colors: string[] = ['#7ACDBB', '#7ED321', '#6DCADE', '#86C3D0'];
  getColor () {
    const color = this.colors[this.count];
    if (this.count === (this.colors.length - 1)) {
      this.count = 0;
    } else {
      this.count++;
    }
    return color;
  }
  constructor() {}
}
