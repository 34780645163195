import {Action} from '@ngrx/store';
import { ExploreCardListType } from 'src/app/_interface/explore-page.types';
import { NotificationType } from 'src/app/_interface/notification.types';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SHOW_NOTIFICATIONS = 'SHOW_NOTIFICATIONS';
export const HIDE_NOTIFICATIONS = 'HIDE_NOTIFICATIONS';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';
export const SET_NOTIFICATIONS_INFO = 'SET_NOTIFICATIONS_INFO';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const CALLED_NOTIFICATION_API = 'CALLED_NOTIFICATION_API';
export const SET_USER_QUESTS = 'SET_USER_QUESTS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const MARK_ALL_NOTIFICATION_READ = 'MARK_ALL_NOTIFICATION_READ';
export const IS_NOTIFICATIONS_LOADING = 'IS_NOTIFICATIONS_LOADING';
export const ALL_NOTIFICATIONS_LOADED = 'ALL_NOTIFICATIONS_LOADED';

export class SetNotificationsInfo implements Action {
  readonly type = SET_NOTIFICATIONS_INFO;

  constructor(public payload: number) {}
}
export class ShowNotifications implements Action {
  readonly type = SHOW_NOTIFICATIONS;
}
export class HideNotifications implements Action {
  readonly type = HIDE_NOTIFICATIONS;
}
export class ResetNotifications implements Action {
  readonly type = RESET_NOTIFICATIONS;
}

export class SetNotifications implements Action {
  readonly type = SET_NOTIFICATIONS;

  constructor(public payload: NotificationType[]) {}
}

export class CalledNotificationApi implements Action {
  readonly type = CALLED_NOTIFICATION_API;

  constructor(public payload: boolean) {}
}

export class SetUserQuests implements Action {
  readonly type = SET_USER_QUESTS;

  constructor(public payload: { [key: number]: ExploreCardListType }) {}
}

export class ClearNotifications implements Action {
  readonly type = CLEAR_NOTIFICATIONS;

  constructor() {}
}

export class MarkAllNotificationRead implements Action {
  readonly type = MARK_ALL_NOTIFICATION_READ;

  constructor() {}
}

export class GetNotifications implements Action {
  readonly type = GET_NOTIFICATIONS;

  constructor(public userId: number, public startCount: number, public sizeNum: number) {}
}

export class IsNotificationsLoading implements Action {
  readonly type = IS_NOTIFICATIONS_LOADING;

  constructor(public payload: boolean) {}
}

export class AllNotificationsLoaded implements Action {
  readonly type = ALL_NOTIFICATIONS_LOADED;

  constructor(public payload: boolean) {}
}

export type NotificationsActions =  ShowNotifications |
                                    HideNotifications |
                                    ResetNotifications |
                                    SetNotificationsInfo |
                                    SetNotifications |
                                    CalledNotificationApi |
                                    SetUserQuests |
                                    ClearNotifications |
                                    MarkAllNotificationRead |
                                    GetNotifications |
                                    IsNotificationsLoading |
                                    AllNotificationsLoaded;
