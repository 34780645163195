import { Component, ComponentFactoryResolver, ElementRef, HostListener, Input, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoPlayerComponent } from '../video-player/video-player.component';

declare var Hammer;

@Component({
  selector: 'app-gallery-fullscreen',
  templateUrl: './gallery-fullscreen.component.html',
  styleUrls: ['./gallery-fullscreen.component.scss']
})
export class GalleryFullscreenComponent implements OnInit {

  @Input('assets') assets: any[];
  
  @Input('focusedIndex') focusedIndex: number = 0;

  @ViewChild('imageContainerElem', {read: ElementRef, static: false}) imageContainerElem: ElementRef;
  
  @ViewChild('rightSwipeElem', {read: ElementRef, static: false}) rightSwipeElem: ElementRef;

  @ViewChild('leftSwipeElem', {read: ElementRef, static: false}) leftSwipeElem: ElementRef;

  @ViewChild('headerElem', {read: ElementRef, static: false}) headerElem: ElementRef;

  currentFocussedIndex: number = 0;

  pageLoaded: boolean = false;

  swipeSlideFocused: number = 0; //it will only be 0, 1 or 2

  constructor(private renderer: Renderer2,
    private activeModal: NgbActiveModal,
    private viewContainer: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver) { }

  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (this.pageLoaded
      && (this.imageContainerElem && !this.imageContainerElem.nativeElement.contains(event.target)) 
      && (this.rightSwipeElem && !this.rightSwipeElem.nativeElement.contains(event.target))
      && (this.leftSwipeElem && !this.leftSwipeElem.nativeElement.contains(event.target))
      && (this.headerElem && !this.headerElem.nativeElement.contains(event.target))) {
        this.closeModal();
    }
  }

  @HostListener('document:keydown', ['$event'])
  keydownEvent(event): void {
    if (!this.pageLoaded) return;

    if (event && event.keyCode === 37) {
      this.onPrevious();
    } else if (event && event.keyCode === 39) {
      this.onNext();
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.onLoad();
    }, 100);
  }

  onLoad() {
    let startIndex = this.focusedIndex < 3 ? 0 : (this.focusedIndex > 3 && (this.focusedIndex > (this.assets.length - 3)) ? (this.assets.length -3) : (this.focusedIndex - 1));
    this.buildContent(startIndex, this.focusedIndex, true);
    
    this.setActiveIndex();

    this.swipeSlideFocused = this.focusedIndex === 0 ? 0 : 1;

    // Get a reference to an element
    var square = document.getElementById('swipejs-cont-gal');

    // Create a manager to manager the element
    var manager = new Hammer.Manager(square);

    // Create a recognizer
    var Swipe = new Hammer.Pan();

    // Add the recognizer to the manager
    manager.add(Swipe);

    // Declare global variables to swiped correct distance
    var deltaX = 0;

    let panStartVal = 0;

    // Subscribe to a desired event
    let _panMove = function(e) {
      // deltaX = (deltaX + e.deltaX);
      if (e.offsetDirection === 2 || e.offsetDirection === 4) {
        this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(' + e.deltaX + 'px, 0, 0)')
      }
    };
    manager.on('panmove', _panMove.bind(this));

    let _panEnd = function(e) {
      if ((Math.abs(e.deltaX - panStartVal)) > (window.innerWidth / 3.5)) {
        if (e.deltaX - panStartVal < 0) {
          this.onNext();
        } else {
          this.onPrevious();
        }
      } else {
        this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(0, 0, 0)');
      }
    }
    manager.on('panend', _panEnd.bind(this));

    let _panStart = function(e) {
      panStartVal = e.deltaX;
      deltaX = e.deltaX;
    }
    manager.on('panstart', _panStart.bind(this));
    
    this.pageLoaded = true;
  }

  buildContent(startWithIndex, buildFromIndex, isFirstTimeLoad = false) {
    let imgParent: HTMLElement[] = this.imageContainerElem.nativeElement.childNodes;
    let focusToBe = 0;
    let focusVideoIndex = 0;

    imgParent.forEach((parentEle, i) => {
      if (startWithIndex >= this.assets.length) return;

      const isAssetVideo = this.assets[startWithIndex].questVideoUrl && this.assets[startWithIndex].questVideoUrl !== '' && this.assets[startWithIndex].questVideoUrl !== 'null'; //this.assets[startWithIndex].isVideo;
      const assetImageUrl = this.assets[startWithIndex].questImageUrl;

      if (parentEle.childNodes && parentEle.childNodes.length > 0) this.renderer.removeChild(parentEle, parentEle.childNodes[0]);
      let divElem = this.renderer.createElement('div');
      this.renderer.addClass(divElem, 'gallery-fs__imgs-container__item');

      if (isAssetVideo) {
        let videoPlayerTemp = this.componentFactoryResolver.resolveComponentFactory(VideoPlayerComponent);
        let videoPlayerComp = this.viewContainer.createComponent(videoPlayerTemp);
        
        videoPlayerComp.instance.videoUrl = this.assets[startWithIndex].questVideoUrl;
        videoPlayerComp.instance.imageUrl = assetImageUrl;
        if (startWithIndex === buildFromIndex && focusToBe === focusVideoIndex && isFirstTimeLoad) {
          videoPlayerComp.instance.startPlay = true;
        }
        this.renderer.appendChild(divElem, videoPlayerComp.location.nativeElement);
      }
      else {
        let img = this.renderer.createElement('img');
        this.renderer.setAttribute(img, 'src', assetImageUrl);

        this.renderer.appendChild(divElem, img);
      }

      this.renderer.appendChild(parentEle, divElem);
      
      this.renderer.removeClass(parentEle, 'current');

      if (startWithIndex === buildFromIndex) {
        this.currentFocussedIndex = buildFromIndex;
        this.focusOnIndex(focusToBe);
      }

      startWithIndex++;
      focusToBe++;
      focusVideoIndex++;
    });
  }

  onNext() {
    if (this.assets.length === 1) {
      this.currentFocussedIndex = 0;
      this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(0, 0, 0)');

      this.setActiveIndex();
      
      return;
    }

    if (this.assets.length === 2) {
      this.currentFocussedIndex = (this.currentFocussedIndex + 1) > 1 ? 1 : (this.currentFocussedIndex + 1);
      this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(-' + (window.innerWidth) + 'px, 0, 0)');
      
      this.setActiveIndex();

      return;
    }

    if (this.assets.length === 3) {
      this.currentFocussedIndex = (this.currentFocussedIndex + 1) > 2 ? 2 : (this.currentFocussedIndex + 1);
      this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(-' + (this.currentFocussedIndex * window.innerWidth) + 'px, 0, 0)');

      this.setActiveIndex();

      return;
    }

    let newIndex = this.currentFocussedIndex + 1;
    if (newIndex <= (this.assets.length - 3)) {
      this.currentFocussedIndex += 1;
      let startFromIndex = newIndex === (this.assets.length - 3) ? (this.assets.length - 3) : (newIndex - 1);
      this.buildContent(startFromIndex, this.currentFocussedIndex);

      this.setActiveIndex();

      return;
    }

    if (newIndex > (this.assets.length - 3) && newIndex <= (this.assets.length - 1)) {
      this.currentFocussedIndex += 1;
      let multiplyBy = Math.abs((this.assets.length - 2) - newIndex - 1);
      this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(-' + (multiplyBy * window.innerWidth) + 'px, 0, 0)');

      this.setActiveIndex();

      return;
    }
  }

  onPrevious() {
    if (this.assets.length === 1) {
      this.currentFocussedIndex = 0;
      this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(0, 0, 0)');

      this.setActiveIndex();

      return;
    }

    if (this.assets.length === 2) {
      this.currentFocussedIndex = (this.currentFocussedIndex - 1) < 1 ? 0 : (this.currentFocussedIndex - 1);
      this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(0, 0, 0)');

      this.setActiveIndex();

      return;
    }

    if (this.assets.length === 3) {
      this.currentFocussedIndex = (this.currentFocussedIndex - 1) < 1 ? 0 : (this.currentFocussedIndex - 1);
      this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(-' + (this.currentFocussedIndex * window.innerWidth) + 'px, 0, 0)');

      this.setActiveIndex();

      return;
    }

    let newIndex = this.currentFocussedIndex - 1;
    if (newIndex >= (this.assets.length - 3)) {
      this.currentFocussedIndex -= 1;
      let startFromIndex = (this.assets.length - 3);
      this.buildContent(startFromIndex, this.currentFocussedIndex);

      this.setActiveIndex();

      return;
    }

    if (newIndex < (this.assets.length - 3) && newIndex >= 2) {
      this.currentFocussedIndex -= 1;
      let startFromIndex = newIndex === 2 ? 0 : (newIndex - 1);
      this.buildContent(startFromIndex, this.currentFocussedIndex);

      this.setActiveIndex();

      return;
    }

    if (newIndex >= 0 && newIndex < 2) {
      this.currentFocussedIndex -= 1;
      this.buildContent(0, this.currentFocussedIndex);
      this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(-' + (newIndex * window.innerWidth) + 'px, 0, 0)');

      this.setActiveIndex();

      return;
    }
  }

  closeModal() {
    this.activeModal.dismiss();
    this.activeModal.close();
  }

  focusOnIndex(toBeFocussed) {
    if (this.assets.length === 1) {
      this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(0, 0, 0)');
      
      return;
    }

    if (this.assets.length === 2) {
      if (toBeFocussed === 0) {
        this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(0, 0, 0)');
      } else {
        this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(-' + (window.innerWidth) + 'px, 0, 0)');
      }

      return;
    }

    this.renderer.setStyle(this.imageContainerElem.nativeElement, 'transform', 'translate3d(-' + (toBeFocussed * window.innerWidth) + 'px, 0, 0)');
  }

  setActiveIndex() {
    this.renderer.setAttribute(this.imageContainerElem.nativeElement, 'active-index', this.currentFocussedIndex.toString());

    this.checkAndStopVideo();
  }

  checkAndStopVideo() {
    const allVideo = this.imageContainerElem.nativeElement.querySelectorAll('video');

    if (allVideo && allVideo.length > 0) {
      allVideo.forEach(videoElem => {
        try {
          videoElem.pause();
          videoElem.currentTime = 0;
        } catch(e) {}
      });
    }
  }
}
