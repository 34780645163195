import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MemberActivity } from 'src/app/_interface/quest.types';
import { ReaquestHeadersService } from 'src/app/_services/requestHeaders.service';

@Injectable({
  providedIn: 'root'
})
export class PeopleLeaderboardService {
  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) {}

  getScoresWithMembers(questId: number, userId: number, teamId: number, pageNumber: number, pageSize: number, payload): Observable<MemberActivity[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<MemberActivity[]>(
      environment.target + environment.context + `/leaderboard/eachactivity/${questId}/${userId}/${teamId || 0}/${pageNumber}/${pageSize}`,
      payload,
      {
        headers: headers
      }
    )
  }

  getValuesWithMembers(questId: number, pageNumber: number, pageSize: number, payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/leaderboard/maxactivity/${questId}/${pageNumber}/${pageSize}`,
      payload,
      {
        headers: headers
      }
    )
  }

  getScoresWithTeamMembers(questId: number, userId: number, teamId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/ranked-team/members/${questId}/${userId}/${teamId || 0}`,
      {
        headers: headers
      }
    )
  }
}
