import {Action} from '@ngrx/store';
import { QuestGalleryImage } from 'src/app/_interface/quest.types';
import {PreparedQuestComment} from '../comment/comment.type';

export const SET_COMMENTS_LIST = 'SET_COMMENTS_LIST';
export const CLEAR_COMMENTS_LIST = 'CLEAR_COMMENTS_LIST';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const UPDATE_COMMENT_MENTION_STATUS = 'UPDATE_COMMENT_MENTION_STATUS';
export const TOGGLE_EDIT_COMMENT = 'TOGGLE_EDIT_COMMENT';
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_REPLY = 'ADD_REPLY';
export const UPDATE_COMMENT_IMAGE = 'UPDATE_COMMENT_IMAGE';

export interface UpdateCommentImagePayload {
  image: QuestGalleryImage;
  index: number;
}

export interface ToggleEditCommentState {
  commentId: number;
  isEditable: boolean;
}

export interface UpdateCommentMentionFriendStatusState {
  commentId: number;
  mentionIndex: number;
}

export class SetCommentsList implements Action {
  readonly type = SET_COMMENTS_LIST;

  constructor(public payload: PreparedQuestComment[]) {
    // console.log('CommentsActions::SetCommentsList', payload);
  }
}

export class ClearCommentsList implements Action {
  readonly type = CLEAR_COMMENTS_LIST;

  constructor() {
    console.log('CommentsActions::ClearCommentsList');
  }
}

export class RemoveComment implements Action {
  readonly type = REMOVE_COMMENT;

  constructor(public commentId: number) {
    console.log('CommentsActions::RemoveComment', commentId);
  }
}

export class UpdateComment implements Action {
  readonly type = UPDATE_COMMENT;

  constructor(public updatedComment: PreparedQuestComment) {
    console.log('CommentsActions::UpdateComment', updatedComment);
  }
}

export class UpdateCommentMentionStatus implements Action {
  readonly type = UPDATE_COMMENT_MENTION_STATUS;

  constructor(public payload: UpdateCommentMentionFriendStatusState) {
    console.log('CommentsActions::UpdateCommentMentionStatus', payload);
  }
}
export class UpdateCommentImage implements Action {
  readonly type = UPDATE_COMMENT_IMAGE;

  constructor(public payload: UpdateCommentImagePayload) {
    console.log('CommentsActions::UpdateCommentImage', payload);
  }
}

export class AddComment implements Action {
  readonly type = ADD_COMMENT;

  constructor(public payload: PreparedQuestComment) {
    console.log('CommentsActions::AddComment', payload);
  }
}

export class AddReply implements Action {
  readonly type = ADD_REPLY;

  constructor(public readonly reply: PreparedQuestComment, public readonly repliedCommentId) {
    console.log('CommentsActions::AddReply', repliedCommentId, reply);
  }
}

export class ToggleEditComment implements Action {
  readonly type = TOGGLE_EDIT_COMMENT;

  constructor(public payload: ToggleEditCommentState) {
    console.log('CommentsActions::ToggleEditComment', payload);
  }
}

export type CommentsActions = SetCommentsList |
  ClearCommentsList |
  RemoveComment |
  UpdateComment |
  AddComment |
  AddReply |
  ToggleEditComment |
  UpdateCommentMentionStatus |
  UpdateCommentImage;
