import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from 'src/app/_store/app.reducers';
import { ReferenceDataService } from '../../../../_services/reference-data.service';
import * as config from '../../../../app.config';
import { HTTPStatus } from '../../../../interceptors/loader.interceptor';
import { SetAutoCheckUserDetail } from '../../auth/store/auth.actions';
import * as fromAuth from '../../auth/store/auth.reducer';

interface PincodeValidation {
  validation: boolean;
  formValue: string;
}

interface NewAccountSavedInfo {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  zip: string;
  // passwordGroup?: {
  //   password1: string;
  //   password2: string;
  // };
  receiveEmail: boolean;
  recaptcha: string;
}

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.scss']
})
export class UserSignupComponent implements OnInit, OnChanges {
  @Input("userEmail") userEmail: string;

  @Input("formSubmitted") formSubmitted: string;

  activityStatusSubscriber: Subscription;
  activityStatus = false;
  
  authState: Observable<fromAuth.State>;
  authSubscriber: Subscription;
  signUpForm: UntypedFormGroup;
  
  reCaptchaToken: string = config.reCaptchaToken;
  
  authSubscribtion: Subscription;
  
  @Output('cancelStepCall') cancelStepCall: EventEmitter<boolean> = new EventEmitter<false>();
  
  @Output('processSignUp') processSignUp: EventEmitter<any> = new EventEmitter<null>();

  constructor(
    private httpStatus: HTTPStatus,
    private referenceDataService: ReferenceDataService,
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef
  ) {
    this.activityStatusSubscriber = this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.activityStatus = status ? status : null;
    });
    this.authState = this.store.select('auth');

    this.signUpForm = this.fb.group({
      'firstName': ['', [Validators.required]],
      'lastName': ['', [Validators.required]],
      'email': ['', [Validators.required]],
      'receiveEmail': [true],
      'recaptcha': [true]
    });

    this.authSubscribtion = this.authState.subscribe((state: fromAuth.State) => {
      if (state.userDetailCheck) {
        const detail = state.userDetailCheck;
        if (detail.firstName && detail.lastName) {
          this.signUpForm.patchValue({
            'firstName': detail.firstName,
            'lastName': detail.lastName
          });
        }

        this.store.dispatch(new SetAutoCheckUserDetail(null));
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.signUpForm.controls.email.setValue(this.userEmail);

    //this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.authSubscriber) {
      this.authSubscriber.unsubscribe();
    }
    if (this.activityStatusSubscriber) {
      this.activityStatusSubscriber.unsubscribe();
    }
  }

  handleSuccess($event: string) {
    this.referenceDataService.verifyCapcha($event).subscribe((res: any) => {
      let disabledSignup = res && res.status && res.status === 200;
      this.signUpForm.patchValue({
        recaptcha: true
      })
    }, err => {
      // console.log('172', err)
    });
  }

  handleReset() {
    this.signUpForm.patchValue({
      recaptcha: true
    })
  }


  handleReady(captchaElem) {
    if (captchaElem) {
      captchaElem.execute();
    }
  }

  handleLoad() {
    // console.log('178 in load')
  }

  cancelStep() {
    this.cancelStepCall.emit(true);
  }

  signUp() {
    this.processSignUp.emit({
      isFormValid: this.signUpForm.valid && this.signUpForm.value.recaptcha,
      formValues: this.signUpForm.value
    });
  }
}

