import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable, forkJoin, of as observableOf } from 'rxjs';
import 'rxjs-compat/add/observable/of';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BrandConfig } from '../_interface/brand.types';
import { CommentMention, QuestComment } from '../_interface/comment.types';
import { UpdateMilestoneTaskCompletion } from '../_interface/dl-milestones.actions';
import { ExploreCardListType } from '../_interface/explore-page.types';
import { FundraisingLinkType } from '../_interface/fundraise-link.types';
import { LeaderboardMemberStatus, LeaderboardScore } from '../_interface/leaderboard.types';
import { MapLocation } from '../_interface/map.types';
import { ManageQuestPayload, QuestServiceInterface } from '../_interface/quest-service.types';
import { IsQuestMemberResponse, MemberActivity, Quest, QuestActivity, QuestDoer, QuestGalleryImage, QuestInfo, QuestLite, QuestMapRoute, QuestMapRoutePoint, QuestMembersApi, QuestTask, QuestUserInfo, RealtimeQuestForm, StartQuestForm } from '../_interface/quest.types';
import { QuestTeam } from '../_interface/team.types';
import { AppState } from '../_store/app.reducers';
import { Utils } from '../_tools/utils';
import { NewQuestGalleryPhoto, QuestPreparedPhoto } from '../components/main/quest/quest.type';
import { CreateTeamFundraiseComponent } from '../components/main/team/create-team-fundraise/create-team-fundraise.component';
import { AccountService } from '../components/pages/account/account.service';
import { AccountColor } from '../components/pages/account/store/account.reducer';
import { getYoutubeId } from '../directives/youtube-validation/youtube-validation.directive';
import { ReaquestHeadersService } from './requestHeaders.service';
import { PreparedQuestComment } from '../components/pages/quest-detail/quest-comments/comment/comment.type';

@Injectable({
  providedIn: 'root',
})
export class QuestService implements QuestServiceInterface {
  questInfoChanged: any = new EventEmitter();
  private fundraising: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService,
    private accountService: AccountService,
    private store: Store<AppState>,
    private notifier: NotifierService,
    private modalService: NgbModal) {
  }

  public static isEditableByUser(quest: QuestInfo, user: QuestUserInfo): boolean {
    return !!quest && !!user && (
      user.id === quest.createdBy || (!!quest.admins && quest.admins.indexOf(user.email) >= 0)
    );
  }

  public static hasUserActivity(questActivity: QuestActivity, userId: number) {
    return !!questActivity && !!userId && questActivity.userId === userId;
  }

  callUpdateForQuest(args?: any): void {
    this.questInfoChanged.emit(args);
  }

  getUpdateForQuest() {
    return this.questInfoChanged;
  }

  addMilestone(questId: number, payload: { task: string; video?: any }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quests/${questId}/add-milestone`,
      payload,
      {headers: headers}
    );
  }

  removeMilestone(payload: { id: number; }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/quest-milestones/remove',
      payload,
      {headers: headers}
    );
  }

  editMilestone(payload: { id: number; task: string; video?: any }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/quest-milestones/edit',
      payload,
      {headers: headers}
    );
  }

  getQuestForPost(questId: number): Observable<QuestInfo> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestInfo>(
      environment.target + environment.context + `/getquestforpost/${questId}`,
      {headers: headers}
    );
  }

  getQuestTasksForUser(questId: number): Observable<QuestTask[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<QuestTask[]>(
      environment.target + environment.context + '/getquesttasksforuser',
      {questId: questId},
      {headers: headers}
    ).pipe(
      map((tasks: any[]) => tasks.map(task => new QuestTask(task)))
    );
  }

  getQuestMembers(questId: number, userId: number, teamId: number): Observable<QuestDoer[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quest-members/${questId}/${userId}/${teamId || 0}`,
      {headers: headers}
    ).pipe(
      map((doers: QuestDoer[]): QuestDoer[] => {
        doers.forEach(doer => {
          if (doer.amountBacked && doer.amountBacked.length) {
            doer.amountBackedSum = doer.amountBacked.reduce((sum, delta) => sum + delta, 0);
          } else {
            doer.amountBackedSum = 0;
          }
          doer.memberStatusObj = {
            Creator: false,
            Admin: false,
            Backer: false,
            Donor: false,
            Supporter: false,
            Doer: false,
            Achiever: false,
            Interested: false
          };
          if (doer.memberStatus && doer.memberStatus.length) {
            doer.memberStatus.forEach(status => {
              if (doer.memberStatusObj.hasOwnProperty(status)) {
                doer.memberStatusObj[status] = true;
              }
            });
          }
        });
        return doers;
      })
    );
  }

  getQuestMembersLimit(questId: number, userId: number, teamId: number): Observable<QuestMembersApi> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<any>(
      environment.target + environment.context + `/leaderboard/memberEachactivity/${questId}/${userId}/${teamId || 0}/1/10`,
      {
        headers: headers
      }
    ).pipe(
      map((memberObj: QuestMembersApi): QuestMembersApi => {
        const doers: MemberActivity[] = memberObj.members;
        doers.forEach(doer => {
          if (doer.amountBacked && doer.amountBacked.length) {
            doer.amountBackedSum = doer.amountBacked.reduce((sum, delta) => sum + delta, 0);
          } else {
            doer.amountBackedSum = 0;
          }
          doer.memberStatusObj = {
            Creator: false,
            Admin: false,
            Backer: false,
            Donor: false,
            Supporter: false,
            Doer: false,
            Achiever: false,
            Interested: false
          };
          if (doer.memberStatus && doer.memberStatus.length) {
            doer.memberStatus.forEach(status => {
              if (doer.memberStatusObj.hasOwnProperty(status)) {
                doer.memberStatusObj[status] = true;
              }
            });
          }
        });
        return memberObj;
      })
    );
  }

  updateTeamInfo(payload: QuestTeam) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.put(
      environment.target + environment.context + `/quest/teams/${payload.teamId}`,
      {
        questTeamId: payload.teamId,
        questTeamName: payload.teamName,
        questTeamLogoUrl: payload.teamLogoUrl,
        questTeamCoverUrl: payload.teamCoverUrl,
        questTeamAction: 'Update',
        coverCenterX: payload.coverCenterX,
        coverCenterY: payload.coverCenterY,
        coverZoomValue: payload.coverZoomValue
      },
      {headers: headers}
    );
  }

  getQuestTeams(questId: number): Observable<QuestTeam[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestTeam[]>(
      environment.target + environment.context + `/quest-teams/${questId}`,
      {headers: headers}
    );
  }

  getParentQuestTeams(questId: number,  userId: number): Observable<Quest[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<Quest[]>(
      environment.target + environment.context + `/quest-parent-teams/${questId}/${userId}`,
      {headers: headers}
    );
  }

  getQuestBrands(questId: number): Observable<BrandConfig[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<BrandConfig[]>(
      environment.target + environment.context + `/quests/${questId}/brand-config`,
      {headers: headers}
    );
  }

  findTeam(questId: number, teamName: string): Observable<QuestTeam> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestTeam>(
      environment.target + environment.context + `/quest-teams/${questId}/for-name`,
      {
        headers: headers,
        params: {'team-name': teamName}
      }
    );
  }

  getTeam(teamId: number): Observable<QuestTeam> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestTeam>(
      environment.target + environment.context + `/quest-teams/${teamId}/for-id`,
      {headers: headers}
    );
  }

  checkQuestTeamName(questId: number, teamName: string): Observable<boolean> {
    return this.http.post<boolean>(
      environment.target + environment.context + `/quest-teams/${questId}/check-name`,
      teamName,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  checkFundraiserCampaignName(campaignName: string): Observable<boolean> {
    return this.http.post<boolean>(
      environment.target + environment.context + `/fundraising/link/check-name`,
      campaignName,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  getQuestLeaderboard(questId: number, attributeId: string): Observable<LeaderboardScore[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<LeaderboardScore[]>(
      environment.target + environment.context + `/quest-leaderboard/${questId}/scores/${attributeId}`,
      {headers: headers}
    );
  }

  updateLeaderBoardMembers(questId: number): Observable<number> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<number>(
      environment.target + environment.context + `/quest-leaderboard/${questId}/members/update`,
      null,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  updateLeaderBoardDoers(questId: number): Observable<number> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<number>(
      environment.target + environment.context + `/quest-leaderboard/${questId}/doers/update`,
      null,
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  patchLeaderboardScore(
    questId: number,
    attributeId: string,
    payload: {
      memberId: number;
      value: number;
      status: LeaderboardMemberStatus;
    }
  ) {
    return this.http.post(
      environment.target + environment.context + `/quest-leaderboard/${questId}/scores/${attributeId}`,
      {...payload},
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  getQuestsInvites(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quests/${questId}/invites`,
      {headers: headers}
    );
  }

  getQuestsAdmins(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quests/${questId}/admins`,
      {headers: headers}
    );
  }

  getQuestEditInfo(questId: number): any {
    return forkJoin([
      this.getQuestForPost(questId),
      this.getQuestsInvites(questId),
      this.getQuestsAdmins(questId)
    ]);
  }

  editQuest(payload: any, userId: number): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/editquest',
      payload,
      {headers: headers}
    ).pipe(
      switchMap((res) => {
        return this.generateQuestClassification(payload.questId).pipe(map(() => res));
      })
    );
  }

  checkIfUserQuestSaved(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/isuserquestsaved',
      {questId: questId},
      {headers: headers}
    );
  }

  cancelQuest(questId) {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.put(
      environment.target + environment.context + `/quest/${questId}/cancel`,
      null,
      {headers: headers}
    );
  }

  getOtherUsers(): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/get-other-users',
      {headers: headers}
    );
  }

  searchUsers(keyword: string): any {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.authContext + `/find/user/${keyword}`,
      {headers: headers}
    );
  }

  completeQuest(questId: number, completeMilestones: boolean, withGeoLocation?: boolean): Observable<void> {
    return new Observable<void>(observer => {
      const doComplete = (point: MapLocation): void => {
        const completeHandle = this.completeQuestInternal(questId, completeMilestones, point).subscribe(
          () => observer.next(null),
          (error) => observer.error(error),
          () => completeHandle.unsubscribe());
      };
      if (withGeoLocation) {
        console.log('Asking for geo-location before completing Quest');
        const geoHandle = Utils.getLocation().subscribe(
          (point) => doComplete(point),
          () => doComplete(null),
          () => geoHandle.unsubscribe());
      } else {
        doComplete(null);
      }
      return observer;
    });
  }

  private completeQuestInternal(questId: number, completeMilestones: boolean, point?: MapLocation): Observable<void> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<void>(
      environment.target + environment.context + '/completequest',
      {questId, completeMilestones, point},
      {headers: headers}
    );
  }

  createQuest(payload: ManageQuestPayload): Observable<ExploreCardListType> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<ExploreCardListType>(
      environment.target + environment.context + '/newquest',
      payload,
      {headers: headers}
    ).pipe(
      switchMap((res: ExploreCardListType) => {
        return this.generateQuestClassification(res.id).pipe(map(() => res));
      })
    );
  }

  private generateQuestClassification(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quest-classification/${questId}`,
      null,
      {headers: headers}
    );
  }

  addQuestGalleryPhoto(payload: NewQuestGalleryPhoto) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/addphototoquest',
      payload,
      {headers: headers}
    );
  }

  getQuestInterests() {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + '/ref/profile-interests',
      {headers: headers}
    );
  }

  startQuest(startQuestForm: StartQuestForm, isPublish: boolean = false): Observable<number> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<number>(
      environment.target + environment.context + `/doquest/${isPublish ? 'publish' : 'join'}`,
      startQuestForm,
      {headers: headers}
    );
  }

  doQuest(questId: number, isPublish: boolean, referrerId?: number, questMode?: string, withGeoLocation?: boolean): Observable<number> {
    return new Observable<number>(observer => {
      const doStart = (point: MapLocation): void => {
        const startHandle = this.startQuest({questId, referrerId, questMode, point}, isPublish).subscribe(
          (started) => observer.next(started),
          (error) => observer.error(error),
          () => startHandle.unsubscribe());
      };
      if (withGeoLocation) {
        console.log('Asking for geo-location before starting Quest');
        const geoHandle = Utils.getLocation().subscribe(
          (point) => doStart(point),
          () => doStart(null),
          () => geoHandle.unsubscribe());
      } else {
        doStart(null);
      }
      return observer;
    });
  }

  updateQuestTask(taskId: number): Observable<boolean> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<boolean>(
      environment.target + environment.context + '/updatetask',
      {taskId: taskId},
      {headers: headers}
    );
  }

  getQuestDetail(questId, userId, teamId?: number): Observable<Quest> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<Quest>(
      environment.target + environment.context + `/quest-page/${questId}/${userId}/${teamId || 0}`,
      {headers: headers}
    );
  }

  getQuestLite(questId: number): Observable<QuestLite> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<QuestLite>(
      environment.target + environment.context + `/quests/${questId}/lite`,
      { headers: headers }
    );
  }

  linkQuest(taskId: number, linkedQuestId: number): Observable<QuestTask> {
    return this.http.post<QuestTask>(
      environment.target + environment.context + `/quest-milestones/add-quest-link`,
      {taskId: taskId, linkedQuestId: linkedQuestId},
      {headers: this.reaquestHeadersService.getHeaders()}
    );
  }

  followQuest(questId: number, follow: boolean) {
    const payload = {
      questId: questId
    };
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.put(
      environment.target + environment.context + `/quest/follow/${questId}/${follow ? 'true' : 'false'}`,
      payload,
      {headers: headers}
    );
  }

  saveQuest(questId) {
    const payload = {
      questId: questId
    };
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/savequest`,
      payload,
      {headers: headers}
    );
  }

  copyQuest(questId) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quests/${questId}/copy`,
      null,
      {headers: headers}
    );
  }

  startFundraising(questId: number,
                   doerId: number,
                   brandUserId: number,
                   targetAmount: number,
                   currency: string,
                   campaignName: string,
                   coverImageUrl: string,
                   centerX: string,
                   centerY: string,
                   zoomValue: string,
                   secondaryBrandUserId?: number,
                   teamId?: number): Observable<FundraisingLinkType> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {questId, doerId, brandUserId, targetAmount, currency, campaignName, coverImageUrl, secondaryBrandUserId, teamId};
    payload['coverCenterX'] = parseFloat(centerX);
    payload['coverCenterY'] = parseFloat(centerY);
    payload['coverZoomValue'] = parseFloat(zoomValue);
    return this.http.post<FundraisingLinkType>(
      environment.target + environment.context + '/fundraising/start',
      payload,
      {headers: headers}
    );
  }

  startTeamAndFundraise(payload: any): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.post<FundraisingLinkType>(
      environment.target + environment.context + '/fundraisinglink/startquest',
      payload,
      {headers: headers}
    );
  }

  getFundraisingInfo(questId: number, userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/fundraising/parties/quest/${questId}/doer/${userId}`,
      {headers: headers}
    );
  }

  getFundraisingLink(questId: number, userId: number, teamId?: number, isQuestTeamMode: boolean = false): Observable<FundraisingLinkType> {
    const headers = this.reaquestHeadersService.getHeaders();
    /**
     * commented below code as the donation was failing for many user
     * **/
    if (teamId || isQuestTeamMode) {
      return this.http.get<FundraisingLinkType>(
        environment.target + environment.context + `/fundraising/link/quest/${questId}/doer/${userId}/teamId/${teamId || 0}`,
        {headers: headers}
      );
    }
    
    return this.http.get<FundraisingLinkType>(
      environment.target + environment.context + `/fundraising/link/quest/${questId}/doer/${userId}`,
      {headers: headers}
    );
  }

  getFundraisingDataFromLinkId(linkId: number): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    
    return this.http.get<any>(
      environment.target + environment.context + `/fundraised/link/${linkId}`,
      {headers: headers}
    )
  }

  getFundraisingDataFromQuestId(questId: number): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get<any>(
      environment.target + environment.context + `/fundraised/quest/${questId}`,
      {headers: headers}
    )
  }

  getQuestCompletionPercent(questId: number, userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      questId: questId
    };
    return this.http.post(
      environment.target + environment.context + `/getquestcompletionpercent/${questId}/${userId}`,
      payload,
      {headers: headers}
    ).pipe(map((res: any) => {
      res.completionPercentage = Number(res.completionPercentage.replace('%', '').trim());

      return res;
    }));
  }

  getQuestComments(questId: number, userId: number, viewerId: number): Observable<PreparedQuestComment[]> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      questId: questId
    };
    return this.http.post(
      environment.target + environment.context + '/getallcommentsforquest',
      payload,
      {headers: headers}
    ).pipe(
      // map((comments: QuestComment[]): QuestComment[] => {
      //   // ? reverse comments
      //   comments.forEach(comment => comment.replies.reverse());
      //   return comments;
      // }),
      map((comments: QuestComment[]): PreparedQuestComment[] => {
        const shouldBeDeleted = [];
        let deletedCount = 0;
        comments.forEach((comment: PreparedQuestComment, index: number) => {
          comment.liked = false;
          comment.editable = false;
          if (comment.likes) {
            // comment.liked = (comment.likes).filter(like => like.liker === viewerId).length > 0;
          }
          // comment.likesCount = comment.likes ? comment.likes.length : 0;
          if (comment.deleted !== 'N' && comment.deleted !== null) {
            shouldBeDeleted.push(index);
          }
          this.prepareComment(comment);
        });
        if (shouldBeDeleted.length > 0) {
          shouldBeDeleted.forEach(key => {
            comments.splice((key - deletedCount), 1);
            deletedCount++;
          });
        }
        return comments;
      })
    );
  }

  addNewQuestComment(questId: number, activityRecordValueId: number, comment: string, parentCommentId: number, mentionUsersStr: string): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      comments: comment,
      activityRecordValueId: activityRecordValueId,
      parentCommentsId: parentCommentId,
      taggedUsers: mentionUsersStr
    };
    return this.http.post(
      environment.target + environment.context + `/comments/${questId}`,
      payload,
      {headers: headers}
    )
    // .pipe(
    //   map((newComment: PreparedQuestComment): PreparedQuestComment => {
    //     return this.prepareComment(newComment);
    //   })
    // );
  }

  addNewQuestCommentOld(questId: number, viewerId: number, comment: string, inReplyToId?: number, imageId?: number): Observable<PreparedQuestComment> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      questId: questId,
      comments: comment,
      inReplyToId: inReplyToId,
      imageId: imageId
    };
    return this.http.post(
      environment.target + environment.context + '/addcomment',
      payload,
      {headers: headers}
    ).pipe(
      map((newComment: PreparedQuestComment): PreparedQuestComment => {
        return this.prepareComment(newComment);
      })
    );
  }

  prepareComment(comment: PreparedQuestComment): PreparedQuestComment {
    comment.limit = 5;

    const formattedStringArray = [];
    let regexSegment = '';

    comment.mentions.forEach((mention, index) => {
      regexSegment += `(@${mention.userName})`;
      if (comment.mentions.length > index + 1) {
        regexSegment += '|';
      }
    });

    const regexp = new RegExp(`\\B${regexSegment}\\b`, 'gmi');
    const commentArray = comment.comment.split(regexp).filter(el => el !== undefined);

    commentArray.forEach((item) => {
      const formattedStringArrayItem: any = {};
      formattedStringArrayItem.text = item;
      if (item.match(regexp)) {
        formattedStringArrayItem.mention = [...comment.mentions].find(mention => '@' + mention.userName === item.match(regexp)[0]);
      }
      formattedStringArray.push(formattedStringArrayItem);
    });

    let str = '';
    let mentionCount = 0;
    formattedStringArray.forEach(item => {
      if (!item.mention) {
        str += item.text;
      } else {
        str += this.buildCommentMention(item.mention, mentionCount);
        mentionCount++;
      }
    });

    comment.formattedComment = str;
    comment.replies = [...comment.replies].map(reply => this.prepareComment(reply));
    return comment;
  }

  buildCommentMention(mention: CommentMention, index: number) {
    // tslint:disable-next-line:max-line-length
    return `<a class="c-comment__mention js-mention" href="javascript:void(0);" data-index="${index}">${mention.firstName} ${mention.lastName}</a>`;
  }

  editQuestComment(commentId: number, value: string, imageId?: number): Observable<PreparedQuestComment> {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      comment: value,
      imageId: imageId
    };
    return this.http.post(
      environment.target + environment.context + `/comment/${commentId}`,
      payload,
      {headers: headers}
    ).pipe(
      map((updatedComment: QuestComment): PreparedQuestComment => {
        return this.prepareComment(updatedComment);
      })
    );
  }

  editQuestCommentReply(commentId: number, comment: string, mentionStr: string) {
    const headers = this.reaquestHeadersService.getHeaders();
    const payload = {
      comments: comment,
      activityRecordValueId: null,
      parentCommentsId: null,
      taggedUsers: mentionStr
    };
    return this.http.post(
      environment.target + environment.context + `/comments/edit/${commentId}`,
      payload,
      {headers: headers}
    )
  }

  toggleQuestLike(questId: number, activityRecordValueId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/likes/${questId}/${activityRecordValueId}`,
      null,
      {headers: headers}
    );
  }

  toggleQuestLikeOld(commentId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/comment/${commentId}/like`,
      null,
      {headers: headers}
    );
  }

  removeQuestComment(commentId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.delete(
      environment.target + environment.context + `/comment/${commentId}`,
      {headers: headers}
    );
  }

  addQuestCoverPhoto(questId: number, photo: File) {
    if (photo && questId) {
      const formData: FormData = new FormData();
      formData.append('questImage', photo, photo.name);
      formData.append('questId', questId.toString());
      const headers = this.reaquestHeadersService.getHeaders();
      return this.http.post(
        environment.target + environment.context + '/newquestimage',
        formData,
        {headers: headers}
      );
    }
    return observableOf([]);
  }

  addQuestCoverPhotoWithUrl(payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + '/newquestimagewithurl',
      payload,
      {headers: headers}
    );
  }

  removeQuestPhoto(photoId) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.delete(
      environment.target + environment.context + `/quest/photo/${photoId}`,
      {headers: headers}
    );
  }

  prepareCoverPhoto(photo: any | null, category?: string | null): QuestPreparedPhoto {
    let url = '/assets/images/useful/categories/';

    if (photo) {
      return {
        jpg: photo,
        webp: null
      };
    } else {
      switch (category) {
        case 'MENTAL':
          url += 'mental';
          break;
        case 'OCCUPATIONAL':
          url += 'occupational';
          break;
        case 'ENVIRONMENTAL':
          url += 'environmental';
          break;
        case 'SOCIAL':
          url += 'social';
          break;
        case 'PHYSICAL':
          url += 'physical';
          break;
        case 'FINANCIAL':
          url += 'financial';
          break;
        default:
          url = null;
          break;
      }
    }

    return url === null ? null : {
      jpg: `${url}.jpg`,
      webp: `${url}.webp`
    };
  }

  getYoutubeId(url: string) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  fundraisingSwitchReset() {
    return this.fundraising.emit(false);
  }

  getFundraisingSwitchValue() {
    return this.fundraising;
  }

  addMapRoute(questId: number, payload: { gpxFile: any; name: string; description: string; }) {
    const headers = this.reaquestHeadersService.getHeaders();
    const data = new FormData();
    data.append('gpxFile', payload.gpxFile);
    data.append('name', payload.name);
    data.append('description', payload.description);
    console.log("Sending payload :: ", payload)
    console.log("To :: ", environment.target + environment.context + `/quest-maproute/${questId}/upload`)
    return this.http.post(
      environment.target + environment.context + `/quest-maproute/${questId}/upload`,
      data,
      {headers: headers, observe: 'response'}
    );
  }

  toggleMapRoutes(questId: number, toggle: boolean) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quest-maproute/${questId}/toggle/${toggle}`,
      {headers: headers, observe: 'response'}
    );
  }

  getMapRoutes(questId: number): Observable<any> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quest-maproute/${questId}/all`,
      {headers: headers}
    ).pipe(map((routes: any[]) => {
      let result: QuestMapRoute[] = [];
      routes.forEach(route => {
        let newRoute = {
          id: route.questMapRoute.id,
          questId: route.questMapRoute.questId,
          name: route.questMapRoute.name,
          description: route.questMapRoute.description,
          distance: route.questMapRoute.distance,
          points: (<Array<any>>route.questMapRoutePoints).map(point => {
            return {
              pointId: point.questMapRoutePointId,
              geoPoint: {
                latitude: point.point.coordinates[0],
                longitude: point.point.coordinates[1],
              } as MapLocation,
              segment: point.segment,
              altitude: point.altitude,
              sequence: point.sequence,
            } as QuestMapRoutePoint;
          })
        } as QuestMapRoute;
        result.push(newRoute);
      })
      return result;
    }));
  }

  renameQuest(questId: number, payload: { questName: string }) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/quest/${questId}/rename`,
      payload,
      {headers: headers}
    );
  }

  
  finishRealtimeQuest(realtimeQuestForm: RealtimeQuestForm) : Observable<boolean> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post<boolean>(
      environment.target + environment.context + `/quest/realtime/${realtimeQuestForm.questId}?format=base64`,
      realtimeQuestForm,
      {headers: headers}
    );
  }

  getActivities(page, size, search, pillar?) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/activity/search/${pillar || null}/${page ? page : 1}/${size ? size : 25}/${search || null}`,
      {headers: headers}
    );
  }

  getGroupsForQuest(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/group/quest/${questId}`,
      {headers: headers}
    );
  }

  getActivitiesForQuest(questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/activity/quest/tasks/${questId}`,
      {headers: headers}
    );
  }

  getVideoForTask(videoUrl) {
    const youtubeId = getYoutubeId(videoUrl);
    let _embeddedVideo = {
      thumbnailUrl: null,
      videoId: null,
      videoUrl: null
    };
    let _videoPayload = null;

    if (youtubeId) {
      _videoPayload = {
        url: `https://www.youtube.com/embed/${youtubeId}`,
        provider: 'YOUTUBE',
        thumbnails: {
          xs: `https://img.youtube.com/vi/${youtubeId}/default.jpg`,
          sm: `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`,
          md: `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`,
        }
      };

      _embeddedVideo = {
        thumbnailUrl: _videoPayload.thumbnails.sm,
        videoId: null,
        videoUrl: _videoPayload.url
      };
    }

    return {
      embed: _embeddedVideo,
      payload: _videoPayload
    };
  }

  getAttributesOfActivity(activityId: number, recordListId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/activity/attributes/units/${activityId}/${recordListId}`,
      {headers: headers}
    );
  }

  logActivity(questId, payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/logactivity/${questId}`,
      payload,
      {headers: headers}
    );
  }

  updateActivity(activityRecordValueId, payload) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.post(
      environment.target + environment.context + `/logactivity/edit/${activityRecordValueId}`,
      payload,
      {headers: headers}
    );
  }

  getLoggedActivities(questId, pageNumber, pageSize) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/logactivity/${questId}/${pageNumber ? pageNumber : 1}/${pageSize ? pageSize : 25}`,
      {headers: headers}
    );
  }

  deleteFeed(activityRecordValueId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/logactivity/delete/${activityRecordValueId}`,
      {headers: headers}
    );
  }

  getRepliesOnComment(commentId: number, pageNumber: number, pageSize: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/post/replies/${commentId}/${pageNumber}/${pageSize}`,
      {headers: headers}
    );
  }

  getCommentsOnPost(postId: number, pageNumber: number, pageSize: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/comments/${postId}/${pageNumber}/${pageSize}`,
      {headers: headers}
    );
  }

  deleteComment(commentId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/comments/delete/${commentId}`,
      {headers: headers}
    );
  }

  checkQuestCustomColorAndApply(questData: Quest, currentUserId: number, creatorId: number) {
    if (questData.quest.accentColor && questData.quest.themeColor && 
      questData.quest.accentColor !== '' && questData.quest.themeColor !== '') {
      this.accountService.changeRootColor({
        accentColor: questData.quest.accentColor,
        themeColor: questData.quest.themeColor
      }, creatorId);
    } else {
      if (currentUserId === creatorId) {
        this.accountService.checkAccountColorAndApply(creatorId);
      } else {
        this.accountService.getCustomColor(creatorId)
          .subscribe((res: AccountColor) => {
            if (res && res.accentColor && res.accentColor !== '' && res.themeColor && res.themeColor !== '') {
              this.accountService.changeRootColor(res, creatorId);
            } else {
              this.accountService.resetAllRootColors();
            }
          })
      }
    }
  }

  getQuestImages(questId: number, doerId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get(
      environment.target + environment.context + `/quest-gallery/${questId}/${doerId}`,
      {headers: headers}
    );
  }

  updateTaskStatus(milestone: QuestTask, index: number, groupIndex: number, cdr: ChangeDetectorRef) {
    this.updateQuestTask(milestone.id)
      .pipe(finalize(() => this.modalService.dismissAll()))
      .subscribe((success) => {
        if (success) {
          this.store.dispatch(new UpdateMilestoneTaskCompletion({ taskIndex: index, groupIndex: groupIndex, value: !milestone.isTaskCompleted }));
        } else {
          this.notifier.notify('warning', 'There was an issue completing your request. Please try again.');
        }
        milestone.isLoading = false;

        cdr.detectChanges();
      }, () => {
        milestone.isLoading = false;
        this.notifier.notify('error', 'There was an issue completing your request. Please try again.');
      });
  }

  checkIfUserVersionExists(questId: number, loggedUserId: number): Observable<{"found": boolean}> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<{"found": boolean}>(
      environment.target + environment.context + `/user-belongsto-quest/${questId}/${loggedUserId}`,
      {headers: headers}
    );
  }

  getStaticMembers(categoryName: string): Observable<QuestDoer[]> {
    const _catName: string = categoryName ? categoryName + '/json/' : 'json/';
    return this.http.get<QuestDoer[]>(
      `https://diemlife-assets.s3.amazonaws.com/templates/${_catName}members.json`
    ).pipe(
      map((doers: QuestDoer[]): QuestDoer[] => {
        doers.forEach(doer => {
          if (doer.amountBacked && doer.amountBacked.length) {
            doer.amountBackedSum = doer.amountBacked.reduce((sum, delta) => sum + delta, 0);
          } else {
            doer.amountBackedSum = 0;
          }
          doer.memberStatusObj = {
            Creator: false,
            Admin: false,
            Backer: false,
            Donor: false,
            Supporter: false,
            Doer: false,
            Achiever: false,
            Interested: false
          };
          if (doer.memberStatus && doer.memberStatus.length) {
            doer.memberStatus.forEach(status => {
              if (doer.memberStatusObj.hasOwnProperty(status)) {
                doer.memberStatusObj[status] = true;
              }
            });
          }

          doer.profilePictureURL = doer.profilePictureURL && doer.profilePictureURL !== '' ? doer.profilePictureURL.replace('${CAT_NAME}', _catName) : doer.profilePictureURL;
        });

        return doers;
      })
    )
  }

  getStaticTeams(categoryName: string): Observable<QuestTeam[]>{
    const _catName: string = categoryName ? categoryName + '/json/' : 'json/';
    return this.http.get<QuestTeam[]>(
      `https://diemlife-assets.s3.amazonaws.com/templates/${_catName}teams.json`
    ).pipe(
      map((teams: QuestTeam[]): QuestTeam[] => {
        teams.forEach(team => {
          team.teamLogoUrl = team.teamLogoUrl.replace('${CAT_NAME}', _catName);
        })

        return teams;
      })
    )
  }

  getStaticGallery(categoryName: string): Observable<QuestGalleryImage[]>{
    const _catName: string = categoryName ? categoryName + '/json/' : 'json/';
    return this.http.get<QuestGalleryImage[]>(
      `https://diemlife-assets.s3.amazonaws.com/templates/${_catName}quest-gallery.json`
    ).pipe(
      map((gallery: QuestGalleryImage[]): QuestGalleryImage[] => {
        gallery.forEach(asset => {
          asset.questImageUrl = asset.questImageUrl.replace('${CAT_NAME}', _catName);
          
          if (asset.questVideoUrl) asset.questVideoUrl = asset.questVideoUrl.replace('${CAT_NAME}', _catName);
        });
        
        return gallery;
      })
    )
  }

  getStaticQuestFeed(categoryName: string): Observable<QuestComment[]>{
    const _catName: string = categoryName ? categoryName + '/json/' : 'json/';
    return this.http.get<QuestComment[]>(
      `https://diemlife-assets.s3.amazonaws.com/templates/${_catName}quest-feed.json`
    ).pipe(
      map((feed: QuestComment[]): QuestComment[] => {
        feed.forEach(comment => {
          comment.images.forEach(asset => {
            asset.imageURL = asset.imageURL.replace('${CAT_NAME}', _catName);
            if (asset.videoURL) asset.videoURL = asset.videoURL.replace('${CAT_NAME}', _catName);
          });

          comment.userImageUrl = comment.userImageUrl.replace('${CAT_NAME}', _catName);

          comment.userComments.forEach(comComment => {
            comComment.userImageUrl = comComment.userImageUrl.replace('${CAT_NAME}', _catName);
          })

          comment.likes.users.forEach(u => {
            u.userImageUrl = u.userImageUrl.replace('${CAT_NAME}', _catName);
          })
        })
        
        return feed;
      })
    )
  }

  getStaticQuestPeopleLeaderboard(categoryName: string): Observable<MemberActivity[]>{
    const _catName: string = categoryName ? categoryName + '/json/' : 'json/';
    return this.http.get<MemberActivity[]>(
      `https://diemlife-assets.s3.amazonaws.com/templates/${_catName}quest-people-activity.json`
    ).pipe(
      map((peopleActivities: MemberActivity[]): MemberActivity[] => {
        peopleActivities.forEach(peopleAct => {
          peopleAct.imageURL = peopleAct.imageURL.replace('${CAT_NAME}', _catName);

          peopleAct.activities.forEach(act => {
            act.imageURL = act.imageURL.replace('${CAT_NAME}', _catName);
          });
        });

        return peopleActivities;
      })
    )
  }

  getStaticQuestTeamLeaderboard(categoryName: string): Observable<MemberActivity[]>{
    const _catName: string = categoryName ? categoryName + '/json/' : 'json/';
    return this.http.get<MemberActivity[]>(
      `https://diemlife-assets.s3.amazonaws.com/templates/${_catName}quest-people-team.json`
    )
  }

  getStaticFundraisingLink(categoryName: string): Observable<FundraisingLinkType>{
    const _catName: string = categoryName ? categoryName + '/json/' : 'json/';
    return this.http.get<FundraisingLinkType>(
      `https://diemlife-assets.s3.amazonaws.com/templates/${_catName}fundraising-link.json`
    ).pipe(
      map((fundraiseData: FundraisingLinkType): FundraisingLinkType => {
        fundraiseData.doer.avatarUrl = fundraiseData.doer.avatarUrl.replace('${CAT_NAME}', _catName);
        fundraiseData.doer.coverPictureURL = fundraiseData.doer.coverPictureURL.replace('${CAT_NAME}', _catName);

        fundraiseData.creator.avatarUrl = fundraiseData.creator.avatarUrl.replace('${CAT_NAME}', _catName);
        fundraiseData.creator.coverPictureURL = fundraiseData.creator.coverPictureURL.replace('${CAT_NAME}', _catName);

        fundraiseData.coverImageUrl = fundraiseData.coverImageUrl.replace('${CAT_NAME}', _catName);

        return fundraiseData;
      })
    )
  }

  getStaticQuestCompletionPercent(categoryName: string) {
    const _catName: string = categoryName ? categoryName + '/json/' : 'json/';
    return this.http.get(
      `https://diemlife-assets.s3.amazonaws.com/templates/${_catName}quest-completion.json`
    ).pipe(map((res: any) => {
      res.completionPercentage = Number(res.completionPercentage.replace('%', '').trim());

      return res;
    }));
  }
  
  publishQuest(questId: number): Observable<{"Success": boolean}> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<{"Success": boolean}>(
      environment.target + environment.context + `/publish-quest/${questId}`,
      {headers: headers}
    );
  }

  deleteDraft(questId: number): Observable<{"Success": boolean}> {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.delete<{"Success": boolean}>(
      environment.target + environment.context + `/delete-quest/${questId}`,
      {headers: headers}
    );
  }

  openCreateTeam() {
    this.modalService.dismissAll();

    this.store.select('quest').pipe(take(1)).subscribe(questState => {
      let modalRef = this.modalService.open(CreateTeamFundraiseComponent, {
        windowClass: 'no-overflow'
      });
      // modalRef.componentInstance.createOnlyTeam = true;
      modalRef.componentInstance.fundraising = questState.isDoerHasFundraisingLink;// && !questState.teamsWithoutFundraising;
      modalRef.componentInstance.questData = questState.selectedQuestDetail;
      modalRef.componentInstance.questTitle = questState.selectedQuestDetail.quest.title;
      modalRef.componentInstance.questId = questState.selectedQuestId;
      modalRef.componentInstance.doerId = questState.selectedQuestUserId;
      this.store.select('userInfo').pipe(take(1)).subscribe(currentUser => {
        modalRef.componentInstance.viewerId = currentUser.id;
      });
      modalRef.componentInstance.multiSeller = questState.selectedQuestDetail.quest.multiSellerEnabled;
      modalRef.componentInstance.questCreatorDetails = questState.selectedQuestDetail.user;
    });
  }

  getStaticAdminDescription(categoryName: string) {
    const _catName: string = categoryName ? categoryName + '/json/' : 'json/';
    return this.http.get(
      `https://diemlife-assets.s3.amazonaws.com/templates/${_catName}admin-quest-description.json`
    );
  }
  
  getUserTeamIdForQuest(questId: number, userId: number) {
    const headers = this.reaquestHeadersService.getHeaders();
    return this.http.get<any>(
      environment.target + environment.context + `/quest-user/team/${questId}/${userId}`,
      {headers: headers}
    );    
  }

  isUserQuestMember(questId: number): Observable<IsQuestMemberResponse> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get<IsQuestMemberResponse>(
      environment.target + environment.context + `/is-quest-member/${questId}`,
      {headers: headers}
    );
  }
}
