import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import { StateKey, TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';

// import 'rxjs/add/observable/throw';

@Injectable()
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {
  constructor(private status: HTTPStatus, 
    private transferState: TransferState, 
    @Inject(PLATFORM_ID) private platformId: any) {}


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method !== 'GET') {
      return next.handle(req).pipe(
        map(event => {
          return event;
        }),
        catchError(() => {
          return next.handle(req);
        }),
        finalize(() => {
          this.status.setHttpStatus(false);
        })
      );
    }

    const key: StateKey<string> = makeStateKey<string>(req.url);

    if (isPlatformServer(this.platformId)) {
      return next.handle(req).pipe(tap((event) => {
        this.transferState.set(key, (<HttpResponse<any>> event).body);
      })); 
    } else {
      const storedResponse = this.transferState.get<any>(key, null);
      if (storedResponse) {
        const response = new HttpResponse({body: storedResponse, status: 200});
        this.transferState.remove(key);
        return of(response);
      } else {
        return next.handle(req).pipe(
          map(event => {
            return event;
          }),
          catchError(() => {
            return next.handle(req);
          }),
          finalize(() => {
            this.status.setHttpStatus(false);
          })
        );
      }
    }

    // return next.handle(req).pipe(
    //   map(event => {
    //     return event;
    //   }),
    //   catchError(() => {
    //     return next.handle(req);
    //     // return Observable.throw(error);
    //   }),
    //   finalize(() => {
    //     this.status.setHttpStatus(false);
    //   })
    // );
  }
}
