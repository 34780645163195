import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ORGANIZATION_TYPE_LIST, WEBSITE_REGEX_VALIDATOR } from 'src/app/app.config';
import { HTTPStatus } from 'src/app/interceptors/loader.interceptor';
import * as Constants from '../../../../../app.constants';

@Component({
  selector: 'app-brand-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss']
})
export class BasicDetailsComponent implements OnInit {
  @Input() formSubmitted = false;
  businessForm: UntypedFormGroup;
  messages = Constants.VALIDATION_MESSAGES;

  organizationTypesArr = ORGANIZATION_TYPE_LIST;
  eventsSubject: Subject<void> = new Subject<void>();

  activityStatus: boolean = false;

  
  @Output('processBrandSignUp') processBrandSignUp: EventEmitter<any> = new EventEmitter<null>();

  constructor(
    private fb: UntypedFormBuilder,
    private httpStatus: HTTPStatus
  ) {
    this.businessForm = this.fb.group({
      'businessName': ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      'website': ['', [Validators.pattern(WEBSITE_REGEX_VALIDATOR)]],
      'orgType': ['', [Validators.required]]
    });

    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      this.activityStatus = status ? status : null;
    });
  }

  ngOnInit() {
  }

  openedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select-opened");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '99';
      };
    }, 300);
  }

  closedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '9';
      };
    }, 500);
  }

  addBrandSignUp() {
    this.formSubmitted = true;
    
    this.processBrandSignUp.emit({
      type: 'basic',
      isFormValid: this.businessForm.valid,
      formValues: this.businessForm.value
    })
  }
}
