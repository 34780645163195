import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Observable, Subject, Subscription } from 'rxjs';
import { AppState, authFormMode } from 'src/app/_store/app.reducers';
import { environment } from '../../../../../environments/environment';
import * as config from '../../../../app.config';
import * as Constants from '../../../../app.constants';
import { NewAccountInfo, SignUpService } from '../../auth/sign-up/sign-up.service';
import * as AuthActions from '../../auth/store/auth.actions';
import * as fromAuth from '../../auth/store/auth.reducer';
import { FormMode } from '../../auth/store/auth.reducer';

import { FacebookLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/_services/common.service';
import { AccountPlan } from 'src/app/_interface/account-tier.types';

interface NewAccountSavedInfo {
  firstName: string;
  lastName: string;
  email: string;
  // country: string;
  // zip: string;
  passwordGroup: {
    password1: string;
    password2: string;
  };
  receiveEmail: boolean;
  recaptcha: string;
}
@Component({
  selector: 'app-dl-log-in',
  templateUrl: './dl-log-in.component.html',
  styleUrls: ['./dl-log-in.component.scss']
})
export class DlLogInComponent implements OnInit, OnDestroy {
  @ViewChild("stepsContainer", {read: ElementRef, static: true}) stepsContainer : ElementRef;

  @ViewChild("loginModalContent", {read: ElementRef, static: true}) loginModalContent : ElementRef;

  messages = Constants.VALIDATION_MESSAGES;
  authSubscribtion: Subscription;
  authState: Observable<fromAuth.State>;
  activeState: string = null;
  signinForm: UntypedFormGroup;
  signinFormSubmitted = false;

  stepNumber: number = 1;
  isUserRegistered: boolean = false; //todo: back to false;
  createUserAs: string = "";
  pincodeForm: UntypedFormGroup;

  formSubmitted: boolean = false;
  isLoading: boolean = false;

  isUserValidated = false;
  userIsDisabled = false;

  passwordType = 'password';

  socialUser: SocialUser;
  
  userSocialLoginThrough = null;
  
  askForEmailSocial = false;

  newAccountSavedInfo: NewAccountSavedInfo | null = null;

  pincodeFormSubmitted: boolean = false;

  showSignUpForm: boolean = false;

  showPasswordRecoverCont = false;

  userFromType: string = 'DiemLife';

  private destroyed$: Subject<void> = new Subject();

  rightContainerImage: string = '/assets/images/useful/get-started/peoples-diemlife-1.webp';

  passwordResetForm: UntypedFormGroup;
  
  token: string;

  passwordToken: string;

  config = config;

  userIdOfLoginTry: number;

  validDomainsRegex = /\b(@yahoo.|@hotmail.|@live.|@aol.|@msn.|@outlook.|@gmail.|@rediff.|@rediffmail.)/g;

  loginUserPlan: AccountPlan;

  @Input('isOnHome') isOnHome: boolean = false;
  
  constructor(
    private store: Store<AppState>,
    private renderer: Renderer2,
    private signUpService: SignUpService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private socialAuthSer: SocialAuthService,
    private notifier: NotifierService,
    public activeModal: NgbActiveModal,
    public commonService: CommonService
    ) {
    this.authState = this.store.select('auth');

    this.setImageUrl();
  }
  
  ngOnInit() {
    // added this for DEV only
    if (!environment.production) {
      this.validDomainsRegex = /\b(@yahoo.|@hotmail.|@live.|@aol.|@msn.|@outlook.|@gmail.|@rediff.|@rediffmail.|@yopmail.|@diemlife.)/g;
    }

    this.socialAuthSer.authState.subscribe(res => {
      if (res && res.provider === this.userSocialLoginThrough) {
        this.socialUser = res;
        this.proceedForSocialLogin();
      } 
    });

    this.stepNumber = 1;

    this.signinForm = this.fb.group({
      'email': new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(config.EMAIL_REGEX_VALIDATOR)
      ]),
      'password': new UntypedFormControl(null, [])
    });

    this.pincodeForm = this.fb.group({
      pincode: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/([0-9])/)])
    });

    this.store
      .pipe(select(authFormMode))
      .subscribe(formModeResp => {
        if (formModeResp === 'logIn' || formModeResp === 'signUp') {
          this.stepNumber = 1;
          this.goBackStep();
          this.activeState = formModeResp ? fromAuth.FormMode[formModeResp] : 'logIn';
  
          this.setHeightOfStepContainer();
          setTimeout(() => {
            this.setHeightOfStepContainer();
          }, 500);
        }
      });

    this.authSubscribtion = this.authState.subscribe((state: fromAuth.State) => {
      if (state && (state.formMode === 'logIn' || state.formMode === 'signUp')) {
        if (!this.signinFormSubmitted) {
          if (state.email) this.signinForm.patchValue({email: state.email || null});
        }
      
        this.setHeightOfStepContainer();
        setTimeout(() => {
          this.setHeightOfStepContainer();
        }, 500);

        if (state.autoCheckEmail) {
          this.checkIfEmailExists();
          this.store.dispatch(new AuthActions.SetAutoCheckEmail(false));
        }

        if (state && state.askForEmailSocial === true) {
          // show the modal to enter the user email
          this.askForEmailSocial = true;
          this.notifier.notify('error', 'Email address required');
        }
      }
    });

    this.passwordResetForm = new UntypedFormGroup({
      'newPassword': new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(config.password.minLength),
        Validators.maxLength(config.password.maxLength)
      ]),
      'newPasswordConfirmation': new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(config.password.minLength),
        Validators.maxLength(config.password.maxLength),
        this.passwordsMatchChecker()
      ])
    });
  }

  private passwordsMatchChecker(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const invalid = this.passwordResetForm
        && control.value
        && this.passwordResetForm.get('newPassword').value
        && this.passwordResetForm.get('newPassword').value !== control.value;
      return invalid ? {'passwordsMatch': true} : null;
    };
  }

  signInFacebook() {
    this.userSocialLoginThrough = 'FACEBOOK';
    this.socialAuthSer.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  proceedForSocialLogin() {
    if (this.userSocialLoginThrough === 'GOOGLE' || this.userSocialLoginThrough === 'FACEBOOK') {
      this.store.dispatch(new AuthActions.TrySigninSocial({
        id: this.socialUser.id || '',
        name: this.socialUser.name || '',
        email: this.socialUser.email || '',
        firstName: this.socialUser.firstName || '',
        lastName: this.socialUser.lastName || '',
        imageUrl: this.socialUser.photoUrl || '',
        provider: this.userSocialLoginThrough
      }));
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();

    if (this.authSubscribtion) {
      this.authSubscribtion.unsubscribe();
    }
    this.socialUser = null;
    this.userSocialLoginThrough = null;
    this.askForEmailSocial = false;
  }

  /**
   * not being used as of now. as brand creation is not allowed from login
   */
  /*
  createBrand() {
    this.formSubmitted = true;

    let newAccountInfo = JSON.parse(sessionStorage.getItem('NEW_ACCOUNT_CREATED_INFO'));
    let payload = {
      name: this.brandSignUpFormValue.formValues.businessName,
      user_id: newAccountInfo['userId'],
      url: this.brandSignUpFormValue.formValues.website,
      type: this.brandSignUpFormValue.formValues.orgType,
      role: "SUPERADMIN"
    };

    this.isLoading = true;
    this.signUpService.createBrand(payload)
      .pipe(finalize(() => {
        this.formSubmitted = false;
      }))
      .subscribe((value) => {
        newAccountInfo = {...newAccountInfo, brand: value};
        sessionStorage.setItem('NEW_ACCOUNT_CREATED_INFO', JSON.stringify(newAccountInfo));
        this.isLoading = false;

        this.userIdOfLoginTry = newAccountInfo.userId;
        this.sendAccountVerificationEmail(newAccountInfo.userId);
        
        this.moveToNextStep();
      }, err => {
        this.notifier.notify('error', 'There was an issue completing your request. Please try again.');
        this.isLoading = false;
      });
  }
  */

  /*saveBrandAddressData() {
    this.formSubmitted = true;

    this.isLoading = true;
    let newAccountInfo = JSON.parse(sessionStorage.getItem('NEW_ACCOUNT_CREATED_INFO'));
    let payload = {
      user_id: newAccountInfo['userId'],
      companyId: newAccountInfo['brand']['id'],
      addressLine1: this.brandSignUpFormValue.formValues.businessAddLine1,
      addressLine2: this.brandSignUpFormValue.formValues.businessAddLine2,
      city: this.brandSignUpFormValue.formValues.city,
      state: this.brandSignUpFormValue.formValues.state,
      zip: this.brandSignUpFormValue.formValues.zipcode,
      country: this.brandSignUpFormValue.formValues.country,
      phone: this.brandSignUpFormValue.formValues.phone
    };
    this.signUpService.updateAddressForBrand(payload).subscribe((value) => {
      // clear NEW_BRAND_INFO & NEW_ACCOUNT_CREATED_INFO
      this.userIdOfLoginTry = newAccountInfo.userId;
      this.sendAccountVerificationEmail(newAccountInfo.userId);
      
      this.isLoading = false;
    });
  }*/

  checkIfEmailExists() {
    this.signinFormSubmitted = true;
    // check if email exists or not
    this.showSignUpForm = false;
    this.createUserAs = '';

    if (!this.signinForm.valid) return false;

    this.isUserValidated = false;
    this.userIsDisabled = false;

    this.isLoading = true;

    this.signUpService.checkEmail(this.signinForm.value.email.trim())
      .pipe(finalize(() => {
        this.signinFormSubmitted = false
      }))
      .subscribe(res => {
        if (res && res['deleted'] === true) {
          this.notifier.notify('error', 'Your account was not found. Please, re-enter your email and proceed.');

          return;
        }

        this.userFromType = res.provider;
        if (res.provider === 'Click') {
          this.isUserRegistered = true;
          this.isUserValidated = true;
          this.isLoading = false;
          
          this.signinForm.controls.password.setValidators([Validators.required,
            Validators.minLength(config.password.minLength),
            Validators.maxLength(config.password.maxLength)]);
          this.moveToNextStep();

          return;
        }

        if (res && res['exists'] === true) {
          this.store.dispatch(new AuthActions.ClearSignUpDetails());

          this.isUserRegistered = true;
          this.loginUserPlan = res && res.plan;
          if (res['active'] === true) {
            if (res['validated'] === true) {
              // email is validated
              // email exists show password
              this.isUserValidated = true;

              if (!res['passwordUpdated']) {
                // show the pasword reset form
                this.isUserRegistered = false;
                this.createUserAs = 'USER';
                this.stepNumber = 3;
                this.isLoading = false;
                this.passwordToken = res['passwordToken'];
                this.moveToNextStep();

                return;
              }

              this.signinForm.controls.password.setValidators([Validators.required,
                Validators.minLength(config.password.minLength),
                Validators.maxLength(config.password.maxLength)]);
                this.moveToNextStep();
            } else {
              // email is not valid
              this.isUserValidated = false;
              this.userIdOfLoginTry = res['userId'];
              
              this.moveToNextStep();
              this.sendAccountVerificationEmail(res['userId']);

              // show verification pin modal.
              this.store.dispatch(new AuthActions.UpdateSignUpDetails({
                email: this.signinForm.value.email
              }));
            }
          } else {
            // user is disabled
            this.userIsDisabled = true;
            this.setHeightOfStepContainer();
          }
        } else {
          // email does not exists, show create which type user 
          this.isUserRegistered = false;

          /*
          //check the type of email using the domain
          const _domainCheck = this.signinForm.value.email.trim().match(this.validDomainsRegex);
          if (_domainCheck && _domainCheck.length > 0) {
            // it must be user
            */
            this.createUserAs = 'USER';
            /*
          } else {
            // it might be biz
            this.createUserAs = 'BRAND';
          }
          */

          this.store.dispatch(new AuthActions.UpdateSignUpDetails({
            email: this.signinForm.value.email.trim()
          }));

          this.moveToNextStep();
        }

        this.isLoading = false;
      });
  }

  moveToNextStep() {
    this.showSignUpForm = false;

    this.renderer.setStyle(this.stepsContainer.nativeElement, 'width', (1 * 100) + '%');
    if (!this.isUserRegistered && (this.createUserAs === 'USER' || (this.createUserAs === 'BRAND' && this.stepNumber < 5))) {
      this.showSignUpForm = true;
    }

    this.stepNumber += 1;
    
    this.setHeightOfStepContainer();
    setTimeout(() => {
      this.setHeightOfStepContainer();
    }, 500);
  }

  signInUser() {
    this.signinFormSubmitted = true;

    if (this.signinForm.invalid) return false;

    const email = this.signinForm.value.email.trim();
    this.isLoading = true;
    const password = this.signinForm.value.password;
    if (this.signinForm.valid) {
      this.authState.pipe(takeUntil(this.destroyed$)).subscribe(authState => {
        this.isLoading = authState.isLoading;
      });

      this.store.dispatch(new AuthActions.AuthSetLoadingState(true));

      // if Click user.i.e Salesforce user then call separate API
      if (this.userFromType.toLowerCase() === 'click') {
        this.store.dispatch(new AuthActions.TryClickSignIn({
          email: email,
          password: password
        }));

        return;
      }

      // calling below for DiemLife users
      this.store.dispatch(new AuthActions.TrySignin({
        email: email,
        password: password
      }));
      this.isLoading = false;
    }
  }

  cancelStep() {
    if (!this.isUserRegistered && this.stepNumber === 2) {
      this.activeModal.close()
    }
  }

  goBackStep(showPasswordRecover?: boolean) {
    const email = this.signinForm.value.email;
    this.stepNumber = ((this.stepNumber - 1) === 0) ? 1 : (this.stepNumber - 1);
    
    if(this.stepNumber === 1) {
      this.signinForm.controls.password.clearValidators();
      this.signinForm.reset();
      this.showSignUpForm = false;
      this.createUserAs = '';
      this.userFromType = 'DiemLife';
      this.showPasswordRecoverCont = showPasswordRecover ? showPasswordRecover : false;
      this.signinForm.controls.email.setValue(email);
    }
    this.setHeightOfStepContainer();
    setTimeout(() => {
      this.setHeightOfStepContainer();
    }, 500);
  }

  setHeightOfStepContainer() {
    setTimeout(() => {
      if (this.loginModalContent.nativeElement.getElementsByClassName("steps-container") && this.loginModalContent.nativeElement.getElementsByClassName("steps-container")[0] &&
        this.loginModalContent.nativeElement.querySelectorAll(".step-"+this.stepNumber+" > .step__content") &&
        this.loginModalContent.nativeElement.querySelectorAll(".step-"+this.stepNumber+" > .step__content")[0] &&
        this.loginModalContent.nativeElement.querySelectorAll('.section-title') && 
        this.loginModalContent.nativeElement.querySelectorAll('.section-title')[0]) {
          this.renderer.setStyle(this.loginModalContent.nativeElement.getElementsByClassName("steps-container")[0], 'min-height', 
            (this.loginModalContent.nativeElement.querySelectorAll(".step-"+this.stepNumber+" > .step__content")[0].clientHeight + this.loginModalContent.nativeElement.querySelectorAll('.section-title')[0].clientHeight + 20) + 'px'
          )
      }
    }, 100);
  }

  userSignUpFormUpdate( event ) {
    if (event.isFormValid && event.isFormValid === true) {
      this.store.dispatch(new AuthActions.IsSignUpFormValid(true));

      this.store.dispatch(new AuthActions.UpdateSignUpDetails({
        firstName: event.formValues.firstName,
        lastName: event.formValues.lastName,
        receiveEmail: event.formValues.receiveEmail,
        recaptcha: event.formValues.recaptcha
      }));

      if (this.createUserAs.toLowerCase() === 'user') {
        this.signUpAccount();
        
        return;
      }

      this.moveToNextStep();
    } else {
      this.store.dispatch(new AuthActions.IsSignUpFormValid(false));
    }
  }

  brandSignUpFormUpdate( event ) {
    // sessionStorage.setItem('NEW_BRAND_INFO', JSON.stringify(this.brandSignUpFormValue));
    
    // this.brandSignUpFormValue[event.type] = event.isFormValid;
    if (event.type === 'basic' && event.isFormValid && event.isFormValid === true) {
      let brandDetailsVal = event.formValues;
      this.store.dispatch(new AuthActions.IsSignUpFormValid(true));

      this.store.dispatch(new AuthActions.UpdateSignUpDetails({
        company: {
          name: brandDetailsVal.businessName,
          website: brandDetailsVal.website,
          orgType: brandDetailsVal.orgType,
          address: {
            lineOne: null,
            country: null,
            zip: null
          }
        }
      }));

      this.formSubmitted = false;

      this.signUpAccount();
    } else {
      this.store.dispatch(new AuthActions.IsSignUpFormValid(false));
    }
  }

  signUpAccount() {
    this.formSubmitted = true;

    this.authState.pipe(take(1))
      .subscribe(authData => {
        if (!authData.signUpFormValid || this.isLoading) {
          return false;
        }

        this.isLoading = true;
        
        let payload: NewAccountInfo = {
          "firstName": authData.signUpFormValue.firstName || '',
          "lastName": authData.signUpFormValue.lastName || '',
          "email": authData.signUpFormValue.email || '',
          "receiveEmail": authData.signUpFormValue.receiveEmail,
          "recaptcha": authData.signUpFormValue.recaptcha,
          "withPin": true,
          "type": this.createUserAs.toLowerCase(),
          company: {
            name: authData.signUpFormValue.firstName + ' ' + authData.signUpFormValue.lastName,
            orgType: "individual",
            address: null
          }
        };

        if (authData.signUpFormValue.company && authData.signUpFormValue.company.name && authData.signUpFormValue.company.name.trim() !== '') {
          payload = {
            ...payload,
            type: "brand",
            company: {
              name: authData.signUpFormValue.company.name,
              orgType: authData.signUpFormValue.company.orgType,
              website: authData.signUpFormValue.company.website,
              phone: authData.signUpFormValue.company.phone,
              address: {
                lineOne: authData.signUpFormValue.company.address && authData.signUpFormValue.company.address.lineOne || '',
                country: authData.signUpFormValue.company.address && authData.signUpFormValue.company.address.country || '',
                zip: authData.signUpFormValue.company.address && authData.signUpFormValue.company.address.zip || '',
                state: null,
                city: null
              }
            }
          };
        }

        console.log('589 signup payload', payload);
        // return;

        this.signUpService.createAccount(payload)
          .pipe(finalize(() => {
            this.formSubmitted = false;
          }))
          .subscribe((value: any) => {
            sessionStorage.setItem('NEW_ACCOUNT_CREATED_INFO', JSON.stringify(value));
            // if (typeObj && typeObj.type === 'user') {
              this.userIdOfLoginTry = value.userId;
              this.sendAccountVerificationEmail(value.userId);

              return;
            // }

            // if (typeObj && typeObj.type === 'brand') {
              // this.isLoading = false;
              
              // this.moveToNextStep();
            // }
          });
      })
  }

  showPasswordRecoveryForm() {
    this.signinFormSubmitted = false;
    this.showPasswordRecoverCont = true;
    this.stepNumber = 1;
    this.goBackStep(true);
  }

  recoverPassword() {
    this.signinFormSubmitted = true;

    const email = this.signinForm.value.email;
    if (email === '' || email === null || !email) {
      this.notifier.notify('error', 'Please enter your email.');

      return false;
    }

    this.isUserValidated = false;
    this.userIsDisabled = false;
    this.isLoading = true;

    this.signUpService.checkEmail(this.signinForm.value.email)
      .pipe(finalize(() => {
        this.signinFormSubmitted = false
      }))
      .subscribe(res => {
        if (res && res['deleted'] === true) {
          this.notifier.notify('error', 'Your account was not found. Please, re-enter your email and proceed.');

          return;
        }

        if (res && res['exists'] === true) {
          if (res['active'] === true) {
            this.store.dispatch(new AuthActions.SelectFormMode(FormMode.recoveryPassword));

            this.store.dispatch(new AuthActions.TryRecoveryPass({
              email: email,
              planId: res.plan && res.plan.id
            }));;
          } else {
            // user is disabled
            this.userIsDisabled = true;
            this.setHeightOfStepContainer();
          }
        } else {
          this.notifier.notify('error', 'Account not found');
        }

        this.isLoading = false;
      });
  }

  sendAccountVerificationEmail(userId?: number) {
    if (!userId) {
      let newAccountInfo = JSON.parse(sessionStorage.getItem('NEW_ACCOUNT_CREATED_INFO')); 
      userId = this.userIdOfLoginTry ? this.userIdOfLoginTry : newAccountInfo.userId;
    }

    this.signUpService.sendVerificationEmail(userId)
      .subscribe(res => {
        if ((this.createUserAs === 'USER' && this.stepNumber === 2) || (this.createUserAs === 'BRAND' && this.stepNumber === 3)) {
          this.moveToNextStep();
        }
        
        this.isLoading = false;
        this.notifier.notify('success', 'Your activation code has been sent.');
      }, err => {

      })
  }

  googleLogin() {
    window.location.href = environment.root + '/api/v2/login/oauth2/google?redirect_uri=' + environment.root + this.router.url;
    return;
  }

  appleLogin() {
    window.location.href = environment.root + "/api/v2/login/apple?redirect_uri=" + environment.root + this.router.url;
  }

  showHidePassword() {
    this.passwordType = (this.passwordType === 'password') ? 'text' : 'password';
  }

  userIsFromClick() {
    return this.userFromType && this.userFromType.toLowerCase() === 'click';
  }

  setImageUrl() {
    this.rightContainerImage = '/assets/images/useful/get-started/peoples-diemlife-'+this.commonService.getRandomInt(1, 9)+'.webp';
  }

  verifyPinAndLogin() {
    if (this.pincodeForm.invalid) return;

    this.isLoading = true;
    this.signUpService.validatePin(this.signinForm.value.email, this.pincodeForm.value.pincode)
      .subscribe(res => {
        // call the validation api fr the returned token
        this.isLoading = false;

        if (res && res.token) {
          this.moveToNextStep();
          this.token = res.token;
          this.verifyUser();
        } else {
          this.notifier.notify('error', 'The activation code you entered is incorrect. Please verify and try again.');
        }
      }, () => {
        this.isLoading = false;
        this.notifier.notify('error', 'Issue processing request.')
      })
  }

  verifyUser() {
    this.signUpService.verifyUserUsingToken(this.token, true)
      .subscribe(res => {
        if (res && res.token) {
          this.passwordToken = res.token;
        }
      })
  }

  resetPassword() {
    if (this.passwordResetForm.valid) {
      this.isLoading = true;

      this.store.dispatch(new AuthActions.RecoveryPass({
        password: this.passwordResetForm.get('newPassword').value,
        repeatPassword: this.passwordResetForm.get('newPasswordConfirmation').value,
        token: this.passwordToken,
        firstTimeSet: true,
        resetFor: this.loginUserPlan && this.loginUserPlan.id
      }));
    }    
  }

  passwordFormValueChange(event) {
    if (event) {
      this.passwordResetForm.patchValue({
        ...event
      });

      // call the password reset api
      this.resetPassword();
    }
  }
}
