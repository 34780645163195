import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

import * as UserFavoritesActions from './user-favorites.actions';
import * as fromUserFavorites from './user-favorites.reducer';
import { ReaquestHeadersService } from '../../../../_services/requestHeaders.service';

@Injectable()
export class UserFavoritesEffects {

  
  profileUserFavorites = createEffect(() => this.actions$
    .pipe(
      ofType(UserFavoritesActions.TRY_GET_USER_FAVORITES),
      switchMap(() => {
        const headers = this.reaquestHeadersService.getHeaders();
        return this.http.get(
          environment.target + environment.context + '/getuserfavorites',
          { headers: headers}
        );
      }),
      mergeMap((data: fromUserFavorites.State[]) => {
        return [
          {
            type: UserFavoritesActions.GET_USER_FAVORITES,
            payload: data
          }
        ];
      })
    ));

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private reaquestHeadersService: ReaquestHeadersService
  ) {}
}
