import * as CommentsActions from './quest-components.actions';
import {PreparedQuestComment} from '../comment/comment.type';

export interface State {
  comments: PreparedQuestComment[];
}

export const initialState: State = {
  comments: []
};

const buildComment = (data: PreparedQuestComment): PreparedQuestComment => {
  return {
    liked: false,
    likesCount: 0,
    editable: false,
    ...data
  };
};
const addReplyComment = (comments: PreparedQuestComment[], action: CommentsActions.AddReply): void => {
  comments.map(comment => {
    if (comment && comment.id === action.repliedCommentId) {
      if (!comment.replies) {
        comment.replies = [];
      }
      comment.replies.unshift(buildComment(action.reply));
    }
    if (comment.replies.length) {
      addReplyComment(comment.replies, action);
    }
    return comment;
  });
};

export function commentsReducer(state = initialState, action: CommentsActions.CommentsActions) {
  switch (action.type) {
    case (CommentsActions.SET_COMMENTS_LIST):
      return {
        ...state,
        comments: action.payload
      };
    case (CommentsActions.CLEAR_COMMENTS_LIST):
      return {
        ...state,
        comments: []
      };
    case (CommentsActions.REMOVE_COMMENT):
      const commentsAfterRemove = [...state.comments].filter(comment => comment && comment.id !== action.commentId).map(comment => {
        comment.replies = [...comment.replies].filter(secondLevel => secondLevel && secondLevel.id !== action.commentId);

        comment.replies.map(secondLevel => {
          secondLevel.replies = [...secondLevel.replies].filter(thirdLevel => thirdLevel && thirdLevel.id !== action.commentId);
          return secondLevel;
        });

        return comment;
      });
      return {
        ...state,
        comments: commentsAfterRemove
      };
    case (CommentsActions.UPDATE_COMMENT):
      const updateComment = (comment: PreparedQuestComment): void => {
        Object.assign(comment, action.updatedComment);
        comment.edited = true;
      };
      const commentsAfterUpdate = [...state.comments].map(comment => {
        if (comment && comment.id === action.updatedComment.id) {
          updateComment(comment);
        }
        comment.replies.forEach(secondLevel => {
          if (secondLevel && secondLevel.id === action.updatedComment.id) {
            updateComment(secondLevel);
          }
          if (secondLevel.replies ? secondLevel.replies.length > 0 : false) {
            secondLevel.replies.forEach(thirdLevel => {
              if (thirdLevel && thirdLevel.id === action.updatedComment.id) {
                updateComment(thirdLevel);
              }
            });
          }
        });
        return comment;
      });
      return {
        ...state,
        comments: commentsAfterUpdate
      };
    case (CommentsActions.UPDATE_COMMENT_IMAGE):
      const commentsArrayOfCommentWithImage = [...state.comments];
      commentsArrayOfCommentWithImage[action.payload.index].image = action.payload.image;
      return {
        ...state,
        comments: commentsArrayOfCommentWithImage
      };
    case (CommentsActions.UPDATE_COMMENT_MENTION_STATUS):
      const hasMentionWithIndex = (comment: PreparedQuestComment): boolean => {
        return !!comment
          && comment.id === action.payload.commentId
          && !!comment.mentions
          && comment.mentions.length
          && !!comment.mentions[action.payload.mentionIndex]
      };
      const commentsAfterMentionUpdate = [...state.comments].map(comment => {
        if (hasMentionWithIndex(comment)) {
          comment.mentions[action.payload.mentionIndex].friendStatus = 0;
        }
        comment.replies.forEach(reply => {
          if (hasMentionWithIndex(reply)) {
            reply.mentions[action.payload.mentionIndex].friendStatus = 0;
          }
        });
        return comment;
      });
      return {
        ...state,
        comments: commentsAfterMentionUpdate
      };
    case (CommentsActions.ADD_COMMENT):
      const newComments = [buildComment(action.payload), ...state.comments];
      return {
        ...state,
        comments: newComments
      };
    case (CommentsActions.ADD_REPLY):
      // const commentsAfterReply = [...state.comments].map(comment => {
      //   if (comment && comment.id === action.repliedCommentId) {
      //     if (!comment.replies) {
      //       comment.replies = [];
      //     }
      //     comment.replies.unshift(buildComment(action.reply));
      //   }
      //   return comment;
      // });
      const commentsAfterReply = [...state.comments];
      addReplyComment(commentsAfterReply, action);
      return {
        ...state,
        comments: commentsAfterReply
      };
    case (CommentsActions.TOGGLE_EDIT_COMMENT):
      const commentsAfterToggleEdit = [...state.comments].map(comment => {
        if (action.payload.isEditable) {
          comment.editable = comment.id === action.payload.commentId;

          if (comment.replies ? comment.replies.length > 0 : false) {
            comment.replies.forEach(secondLevel => {
              secondLevel.editable = secondLevel.id === action.payload.commentId;

              if (secondLevel.replies ? secondLevel.replies.length > 0 : false) {
                secondLevel.replies.forEach(thirdLevel => {
                  thirdLevel.editable = thirdLevel.id === action.payload.commentId;
                });
              }
            });
          }
        } else {
          comment.editable = false;
          comment.replies.forEach(secondLevel => {
            secondLevel.editable = false;

            if (secondLevel.replies ? secondLevel.replies.length > 0 : false) {
              secondLevel.replies.forEach(thirdLevel => {
                thirdLevel.editable = false;
              });
            }
          });
        }
        return comment;
      });
      return {
        ...state,
        comments: commentsAfterToggleEdit
      };
    default:
      return state;
  }
}
