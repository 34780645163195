import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthGuardService {
  public subject: Subject<void> = new Subject<void>();

  update() {
    this.subject.next();
  }
  getSubject(): Subject<void> {
    return this.subject;
  }
}
