import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ReaquestHeadersService} from '../../../_services/requestHeaders.service';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Utils} from '../../../_tools/utils';
import { MyQuestsServiceTypes } from '../../../_interface/my-quests.service.types';
import { ExploreCardBaseType, ExploreCardListType } from '../../../_interface/explore-page.types';
import { MapLocation } from '../../../_interface/map.types';

@Injectable({
    providedIn: 'root'
})
export class MyQuestsSevice implements MyQuestsServiceTypes {
  constructor (
    private reaquestHeadersService: ReaquestHeadersService,
    private http: HttpClient
  ) {}

  unsaveQuest (questId) {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.put(
      environment.target + environment.context + `/quest/${questId}/unsave`,
      null,
      { headers: headers }
    );
  }
  getAllActiveQuestsForUser(): Observable<ExploreCardListType[]> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get<ExploreCardListType[]>(
      environment.target + environment.context + '/quests/all-active',
      { headers: headers }
    );
  }
  getQuestsSavedForUser () {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get(
      environment.target + environment.context + '/getquestssavedforuser',
      { headers: headers }
    );
  }
  getQuestsCompletedForUser(): Observable<ExploreCardListType[]> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get<ExploreCardListType[]>(
      environment.target + environment.context + '/quests/all-completed',
      { headers: headers }
    );
  }
  getUserInfo () {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get(
      environment.target + environment.context + '/getuserinfo',
      { headers: headers }
    );
  }
  // doQuest (questId) {
  //   const headers = this.reaquestHeadersService.getHeaders();

  //   const payload = {
  //     questId: questId
  //   };
  //   return this.http.post(
  //     environment.target + environment.context + '/doquest',
  //     payload,
  //     { headers: headers }
  //   );
  // }

  repeatQuest(questId: number, withGeoLocation?: boolean, resetTasks?: boolean): Observable<void> {
    return new Observable<void>((observer) => {
      const doRepeat = (point: MapLocation): void => {
        const completeHandle = this.repeatQuestInternal(questId, point, resetTasks).subscribe(
          () => observer.next(null),
          (error) => observer.error(error),
          () => completeHandle.unsubscribe());
      };
      if (withGeoLocation) {
        console.log('Asking for geo-location before repeating Quest');
        const geoHandle = Utils.getLocation().subscribe(
          (point) => doRepeat(point),
          () => doRepeat(null),
          () => geoHandle.unsubscribe());
      } else {
        doRepeat(null);
      }
      return observer;
    });
  }

  private repeatQuestInternal(questId: number, point?: MapLocation, resetTasks?: boolean): Observable<void> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.post<void>(
      environment.target + environment.context + `/quests/${questId}/repeat`,
      { point, resetTasks },
      { headers: headers }
    );
  }

  getMyQuestStats () {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get(
      environment.target + environment.context + '/quests/stats',
      { headers: headers }
    );
  }
  toggleQuestStar (questId: number) {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.post(
      environment.target + environment.context + `/starred-quests/toggle/${questId}`,
      null,
      { headers: headers }
    );
  }

  getActiveQuestsForUser(pageNumber: number, pageSize: number): Observable<ExploreCardBaseType[]> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get<ExploreCardBaseType[]>(
      environment.target + environment.context + `/quests/active/${pageNumber}/${pageSize}`,
      { headers: headers }
    );
  }

  getCompleteQuestsForUser(pageNumber: number, pageSize: number): Observable<ExploreCardBaseType[]> {
    const headers = this.reaquestHeadersService.getHeaders();

    return this.http.get<ExploreCardBaseType[]>(
      environment.target + environment.context + `/quests/completed/${pageNumber}/${pageSize}`,
      { headers: headers }
    );
  }

  printfulApi() {
    return this.http.get<ExploreCardBaseType[]>(
      `https://api.printful.com/stores`,
      {
        headers: new HttpHeaders({'Authorization': 'Bearer CdiEZRWe7H3OGtdgYbXxDmC9a91edMhRNsIqT0Nh'})
      }
    );
  }
}
