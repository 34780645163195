import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, HostBinding } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationMessages, VALIDATION_MESSAGES } from 'src/app/app.constants';

export interface UiControlClassObject {
  [key: string]: boolean;
}

@Component({
  selector: 'app-ui-control',
  templateUrl: './ui-control.component.html'
})
export class UiControlComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() controlClass: string[] | UiControlClassObject;
  @Input() legend: string;
  @Input() formIsSubmitted: boolean;
  @Input() template: TemplateRef<any>;
  @Input() validationTemplate?: TemplateRef<any>;
  @Input() mustBeTouched?: boolean;
  @Output() teamsTouched = new EventEmitter();
  touched = false;

  vldMsg: ValidationMessages = VALIDATION_MESSAGES;

  constructor() { }

  @HostBinding('class.mb-0')
  public get marginBottom(): boolean {
    return true;
  }

  @HostBinding('class.is-invalid')
  public get isValid(): boolean {
    if (this.control) {
      return (this.control.touched || this.formIsSubmitted) && this.control.invalid;
    } else {
      return false;
    }
  }

  ngOnInit() {
  }

  onClick() {
    this.touched = true;
    this.teamsTouched.emit(this.touched);
  }

  isEmailField(): boolean {
    return this.legend && this.legend.toLocaleLowerCase().indexOf('email') > -1;
  }
}
