import { MemberActivity } from '../quest.types';

export class SortMembersUtils {

  private SortMembersUtils() {
  }

  public static sortDoers(doers: MemberActivity[], prioritizeBackers: boolean): MemberActivity[] {

    const compareDoers = (left: MemberActivity, right: MemberActivity, prioritizeAdmins): number => {
      if (prioritizeAdmins && left.memberStatusObj.Creator) {
        return -1;
      } else if (prioritizeAdmins && right.memberStatusObj.Creator) {
        return 1;
      } else if (prioritizeAdmins && left.memberStatusObj.Admin) {
        return -1;
      } else if (prioritizeAdmins && right.memberStatusObj.Admin) {
        return 1;
      } else if (left.memberStatusObj.Backer && right.memberStatusObj.Backer) {
        // TODO: Get the sorting score in the member activity api
        // if (left.amountBackedSum === right.amountBackedSum) {
        //   return right.sortingScore - left.sortingScore;
        // } else {
          return right.amountBackedSum - left.amountBackedSum;
        // }
      } else if (left.memberStatusObj.Backer) {
        return -1
      } else if (right.memberStatusObj.Backer) {
        return 1;
      } else {
        return 1;
        // TODO: Get the sorting score in the member activity api
        // return right.sortingScore - left.sortingScore;
      }
    };

    if (prioritizeBackers) {
      const calculateResortScore = (doer): number => {
        if (doer.memberStatusObj.Backer) {
          return 3;
        } else if (doer.memberStatusObj.Doer) {
          return 2;
        } else if (doer.memberStatusObj.Achiever) {
          return 1;
        } else {
          return 0;
        }
      };

      doers.sort((left: MemberActivity, right: MemberActivity) => {
        const leftResortScore = calculateResortScore(left);
        const rightResortScore = calculateResortScore(right);

        if (leftResortScore === rightResortScore) {
          return compareDoers(left, right, false);
        } else {
          return rightResortScore - leftResortScore;
        }
      });
    } else {
      doers.sort((left: MemberActivity, right: MemberActivity) => {
        return compareDoers(left, right, true);
      });
    }
    return doers;
  }
}
