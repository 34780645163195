import {Action} from '@ngrx/store';
import {FormMode, PincodeValidation} from './auth.reducer';
import { NewAccountInfo } from '../sign-up/sign-up.service';

export const TRY_SIGNIN = 'TRY_SIGNIN';
export const TRY_SIGNIN_CLICK = 'TRY_SIGNIN_CLICK';
export const TRY_SIGNIN_SOCIAL = 'TRY_SIGNIN_SOCIAL';
export const SIGNIN = 'SIGNIN';
export const TRY_LOGOUT = 'TRY_LOGOUT';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const TRY_REFRESH_TOKEN = 'TRY_REFRESH_TOKEN';
export const SET_EXPIRES = 'SET_EXPIRES';
export const TRY_RECOVERY_PASS = 'TRY_RECOVERY_PASS';
export const PASS_RECOVERY_REQUESTED = 'PASS_RECOVERY_REQUESTED';
export const RECOVERY_PASS = 'RECOVERY_PASS';
export const SET_PIN_INFO = 'SET_PIN_INFO';
export const AUTH_UPDATE_FORM_STATE = 'AUTH_UPDATE_FORM_STATE';
export const SELECT_FORM_MODE = 'SELECT_FORM_MODE';
export const SET_DEFAULT_EMAIL = 'SET_DEFAULT_EMAIL';
export const SET_AUTOCHECK_EMAIL = 'SET_AUTOCHECK_EMAIL';
export const AUTH_SET_LOADING_STATE = 'AUTH_SET_LOADING_STATE';
export const SET_AUTOCHECK_USER_DETAIL = 'SET_AUTOCHECK_USER_DETAIL';
export const ASK_FOR_EMAIL_AND_LOGIN_SOCIAL = 'ASK_FOR_EMAIL_AND_LOGIN_SOCIAL';
export const UPDATE_LAST_LOGIN = 'UPDATE_LAST_LOGIN';
export const UPDATE_SIGNUP_DETAILS = 'UPDATE_SIGNUP_DETAILS';
export const CLEAR_SIGNUP_DETAILS = 'CLEAR_SIGNUP_DETAILS';
export const SIGNUP_FORM_VALID = 'SIGNUP_FORM_VALID';
export const LOGIN_PLATFORM_TO_COMPANY = 'LOGIN_PLATFORM_TO_COMPANY';
export const RESET_LOGIN_TO_PLATFORM = 'RESET_LOGIN_TO_PLATFORM';
export const PLATFORM_COMPANY_CHANGED = 'PLATFORM_COMPANY_CHANGED';

export class AuthSetLoadingState implements Action {
  readonly type = AUTH_SET_LOADING_STATE;

  constructor(public payload: boolean) {}
}

export class AuthUpdateFormState implements Action {
  readonly type = AUTH_UPDATE_FORM_STATE;

  constructor(public payload: boolean) {
  }
}

export class SetPinInfo implements Action {
  readonly type = SET_PIN_INFO;

  constructor(public payload: PincodeValidation) {
  }
}

export class TryRecoveryPass implements Action {
  readonly type = TRY_RECOVERY_PASS;

  constructor(public payload: { email: string, planId: number }) {
  }
}

export class PassRecoveryRequested implements Action {
  readonly type = PASS_RECOVERY_REQUESTED;

  constructor() {}
}

export class RecoveryPass implements Action {
  readonly type = RECOVERY_PASS;

  constructor(public payload: PasswordRecoveryData) {
  }
}

export class TrySignin implements Action {
  readonly type = TRY_SIGNIN;

  constructor(public payload: { email: string, password: string }) {
  }
}

export class TryClickSignIn implements Action {
  readonly type = TRY_SIGNIN_CLICK;

  constructor(public payload: { email: string, password: string }) {
  }
}

export class TrySigninSocial implements Action {
  readonly type = TRY_SIGNIN_SOCIAL;

  constructor(public payload: { id: string, 
    name: string,
    email: string
    firstName: string
    lastName: string
    imageUrl: string,
    provider: string }) {
  }
}

export class Signin implements Action {
  readonly type = SIGNIN;
}

export class TryLogout implements Action {
  readonly type = TRY_LOGOUT;
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class SetToken implements Action {
  readonly type = SET_TOKEN;

  constructor(public payload: { token: string, tokenUpdateTime: number }) {
  }
}

export class RefreshToken implements Action {
  readonly type = REFRESH_TOKEN;

  constructor(public payload: { token: string, tokenUpdateTime: number }) {
  }
}

export class TryRefreshToken implements Action {
  readonly type = TRY_REFRESH_TOKEN;
}

export class SetExpires implements Action {
  readonly type = SET_EXPIRES;

  constructor(public payload: number) {
  }
}

export class SelectFormMode implements Action {
  readonly type = SELECT_FORM_MODE;

  constructor(public mode: FormMode) {
    console.log('AuthActions::SelectFormMode', mode);
  }
}

export class SetDefaultEmail implements Action {
  readonly type = SET_DEFAULT_EMAIL;

  constructor(public email: string) {
    console.log('AuthActions::SetDefaultEmail', email);
  }
}

export class SetAutoCheckEmail implements Action {
  readonly type = SET_AUTOCHECK_EMAIL;

  constructor(public autoCheck: boolean) {
    console.log('AuthActions::SetAutoCheckEmail', autoCheck);
  }
}

export class SetAutoCheckUserDetail implements Action {
  readonly type = SET_AUTOCHECK_USER_DETAIL;

  constructor(public autoCheckDetail: any) {
    console.log('AuthActions::SetAutoCheckUserDetail', autoCheckDetail);
  }
}

export class AskForEmailAndLoginSocial implements Action {
  readonly type = ASK_FOR_EMAIL_AND_LOGIN_SOCIAL;

  constructor(public askForEmailSocial: boolean) {
    console.log('AuthActions::AskForEmailAndLoginSocial', askForEmailSocial);
  }
}

export class UpdateLastLogin implements Action {
  readonly type = UPDATE_LAST_LOGIN;

  constructor(public emailId: string) {}
}

export class UpdateSignUpDetails implements Action {
  readonly type = UPDATE_SIGNUP_DETAILS;

  constructor(public payload: Partial<NewAccountInfo>) {}
}

export class ClearSignUpDetails implements Action {
  readonly type = CLEAR_SIGNUP_DETAILS;

  constructor() {}
}

export class IsSignUpFormValid implements Action {
  readonly type = SIGNUP_FORM_VALID;

  constructor(public payload: boolean) {}
}

export class LoginPlatformToCompany implements Action {
  readonly type = LOGIN_PLATFORM_TO_COMPANY;

  constructor(public payload: {userId: number, companyId: number, currentCompanyId: number}) {}
}

export class ResetLoginToPlatform implements Action {
  readonly type = RESET_LOGIN_TO_PLATFORM;

  constructor() {}
}

export class PlatformCompanyChanged implements Action {
  readonly type = PLATFORM_COMPANY_CHANGED;

  constructor(public payload: {oldId: number, newId: number}) {}
}

export type AuthActions = TrySignin |
  Signin |
  TryLogout |
  Logout |
  SetToken |
  TryRefreshToken |
  RefreshToken |
  SetExpires |
  TryRecoveryPass |
  PassRecoveryRequested |
  RecoveryPass |
  SetPinInfo |
  AuthUpdateFormState |
  SelectFormMode |
  SetDefaultEmail |
  SetAutoCheckEmail |
  SetAutoCheckUserDetail |
  AuthSetLoadingState |
  AskForEmailAndLoginSocial |
  TryClickSignIn |
  UpdateSignUpDetails |
  ClearSignUpDetails |
  IsSignUpFormValid |
  LoginPlatformToCompany |
  PlatformCompanyChanged |
  ResetLoginToPlatform;

export interface PasswordRecoveryData {
  token: string;
  password: string;
  repeatPassword: string;
  firstTimeSet?: boolean;
  resetFor?: number;
}
